<template>
    <div v-if="knumber == 6" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="top-header">
            <div class="flex items-center gap-3">
                <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                    <i class="el-icon-arrow-left"></i>
                </button>
                <h1>{{ $route.params.edit ? $route.params.edit == '0' ? `"${spec1.name_cyr}" - ${$t('building_data')}` :
                    `"${spec1.name_cyr}" - ${$t('change_building_data')}` : $t('add_new_building')}}</h1>
            </div>
        </div> 
        <div v-if="!['approved', 'rejected'].includes(spec1.status) && ['admin', 'org_admin', 'ministry_admin'].includes(role)" class="w-full p-3 rounded-xl bg-gray-100 mb-8 flex items-center gap-2">
            <el-popover placement="bottom" width="310" v-model="visibleApprove">
                <p class="mb-3">{{ $t('unattested_approve_warn') }}</p>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visibleApprove = false">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" size="mini" @click="setStatus('approved', spec1.id)">{{ $t('approve_btn')}}</el-button>
                </div>
                <button slot="reference" class="button bg-green-500 text-white font-bold">{{ $t('approve_btn')}}</button>
            </el-popover>
            <el-popover placement="bottom" width="310" v-model="visibleReject">
                <p class="mb-3">{{ $t('unattested_reject_warn') }}</p>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visibleReject = false">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" size="mini" @click="reject_info.id = spec1.id; reject_info.modal = true">{{$t('reject_btn') }}</el-button>
                </div>
                <button slot="reference" class="button bg-red-500 text-white font-bold">{{ $t('reject_btn') }}</button>
            </el-popover>
        </div>


        <div class="wp-body">
            <div v-if="spec1.description" class="w-full p-3 rounded-xl bg-red-50 mb-4">
                <h1 class="font-bold text-sm mb-2">{{ $t('cancel_issue') }}</h1>
                <p class="text-sm">{{ spec1.description }}</p>
            </div>
            <header class="wp-header">
                <div class="flex items-center justify-between w-full">
                    <div class="title-second-header mb-0 ml-0">{{ $t('new_building_all_data') }}:</div>
                    <div class="flex aligin-center" v-if="spec1 && spec1.finalconclusion == 3">
                        <p class="m-0 mr-2"> {{ $t('investment_project') }} </p>
                        <InvestmentSwitch v-model="spec1.is_invest" :id="spec1.id" :disabled="true" />
                    </div>
                </div>
            </header>
            <el-form :model="spec1" :rules="rules1" ref="spec1" class="Form">
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('cadaster_number') }} </p>
                                <el-form-item prop="cadastral_number">
                                    <el-input name="cadaster_number" disabled v-model="spec1.cadastral_number"
                                        :placeholder="`${$t('example')}: 10:01:01:01:01:1111:1111:111`" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_or_name') }} </p>
                                <el-form-item prop="name_cyr">
                                    <el-input name="building_or_name" :disabled="disabled" v-model="spec1.name_cyr"
                                        :placeholder="$t('building_or_name')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_or_region') }} </p>
                                <el-form-item prop="region">
                                    <el-select name="building_or_region" disabled v-model="spec1.region"
                                        :loading="loading" class="w-full" :placeholder="$t('building_or_region')">
                                        <el-option v-for="item in dictionary.region" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.shared_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_or_district') }} </p>
                                <el-form-item prop="district">
                                    <el-select name="building_or_district" disabled v-model="spec1.district"
                                        class="w-full" clearable :placeholder="$t('building_or_district')">
                                        <el-option v-for="item in current_district" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.shared_id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>

                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('building_or_neoghborhood') }} </p>
                                <el-form-item prop="neigboardhood">
                                    <el-input name="building_or_neoghborhood" v-if="spec1.neighborhood" disabled
                                        v-model="spec1.neighborhood" :placeholder="$t('building_or_neoghborhood')"
                                        clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_or_street') }} </p>
                                <el-form-item prop="address">
                                    <el-input name="building_or_street" disabled v-model="spec1.address"
                                        :placeholder="$t('building_or_street')" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('floor_count') }} </p>
                                <el-form-item prop="numberfloors">
                                    <el-select name="floor_count" :disabled="disabled" v-model="spec1.numberfloors"
                                        class="w-full" :placeholder="$t('floor_count')" clearable allow-create
                                        filterable>
                                        <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>

                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('building_or_year_construction') }} </p>
                                <el-form-item prop="year_construction">
                                    <el-date-picker name="building_or_year_construction"
                                        :disabled="errors.year_construction || disabled" style="width: 100%"
                                        v-model="spec1.year_construction" type="year" value-format="yyyy"
                                        :placeholder="$t('building_or_year_construction')"
                                        :picker-options="pickerOptions">
                                    </el-date-picker>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_one_category') }} </p>
                                <el-form-item prop="building_group">
                                    <el-select name="building_one_category" v-model="spec1.building_group"
                                        :disabled="disabled" class="w-full" :placeholder="$t('building_one_category')"
                                        clearable>
                                        <el-option v-for="item in dictionary.buildinggroup" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_or_geo') }} </p>
                                <el-form-item prop="geolocation">
                                    <div class="flex align-top">
                                        <el-input name="building_or_geo" disabled style="width: 100%" v-model="coor"
                                            :placeholder="$t('building_or_geo')" />
                                    </div>
                                    <div style="position: relative; overflow: hidden; margin-top: 10px;"
                                        class="flex justify-end">
                                        <el-tooltip style="margin:0" effect="dark"
                                            :content="disabled ? $t('show_coor') : $t('change_coor')" placement="bottom">
                                            <el-button class="primary-btn " id="change_coor"
                                                style="border-radius:5px; font-size:14px; font-size:14px;   padding:15px 20px;"
                                                @click="EditMap(spec1.district)">
                                                <i class="el-icon-location-outline mr-1"></i>
                                                <span v-if="disabled"> {{ $t('show_coor') }} </span>
                                                <span v-else> {{ $t('change_coor') }} </span>
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                </el-form-item>
                            </label>
                        </el-col>
                    </el-row>
                </div>
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('building_buildingseismicity') }} </p>
                                <el-form-item prop="buildingseismicity">
                                    <el-select name="building_buildingseismicity" :disabled="disabled"
                                        v-model="spec1.buildingseismicity"
                                        :placeholder="$t('building_buildingseismicity')" class="w-full" clearable>
                                        <el-option v-for="item in dictionary.buildingseismicity" :key="item.id"
                                            :label="item.number" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('buildng_constructivesolution') }} </p>
                                <el-form-item prop="constructivesolution">
                                    <el-select name="buildng_constructivesolution" :disabled="disabled"
                                        v-model="spec1.constructivesolution"
                                        :placeholder="$t('buildng_constructivesolution')" class="w-full" clearable>
                                        <el-option v-for="item in dictionary.constructivesolution" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('building_plannedshape') }} </p>
                                <el-form-item prop="plannedshape">
                                    <el-select name="building_plannedshape" :disabled="disabled"
                                        v-model="spec1.plannedshape" class="w-full"
                                        :placeholder="$t('building_plannedshape')" clearable>
                                        <el-option v-for="item in dictionary.plannedshape" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>

                            <div class="item">
                                <p> {{ $t('building_evral_size') }} </p>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item prop="overall_size.length">
                                            <el-input name="overall_size_length" type="number" :disabled="disabled"
                                                v-model="spec1.overall_size.length" :placeholder="$t('lengths')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="overall_size.width">
                                            <el-input name="overall_size_width" type="number" :disabled="disabled"
                                                v-model="spec1.overall_size.width" :placeholder="$t('width')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="overall_size.height">
                                            <el-input name="overall_size_height" type="number" :disabled="disabled"
                                                v-model="spec1.overall_size.height" :placeholder="$t('height')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="overall_size.unit">
                                            <el-select name="overall_size_unit" :disabled="disabled"
                                                v-model="spec1.overall_size.unit" class="w-full" clearable>
                                                <el-option v-for="item in units.unit_1" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <label class="item">
                                <p> {{ $t('divided_into_sections') }} </p>
                                <el-form-item prop="divided_into_sections">
                                    <el-select name="divided_into_sections" :disabled="disabled"
                                        v-model="spec1.divided_into_sections" class="w-full"
                                        :placeholder="$t('divided_into_sections')" clearable>
                                        <el-option v-for="item in dictionary.dividedintosections" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <div class="item">
                                <p>{{ $t('floors_height') }}</p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="floors_height.value">
                                        <el-input name="floors_height" type="number" :disabled="disabled"
                                            :placeholder="$t('floors_height')" v-model="spec1.floors_height.value"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item prop="floors_height.unit">
                                        <el-select name="floors_height_unit" :disabled="disabled"
                                            v-model="spec1.floors_height.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id"
                                                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                            <label class="item">
                                <p> {{ $t('part_attic') }} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="part_attic.value">
                                        <el-input name="part_attic" type="number" :placeholder="$t('part_attic')"
                                            :disabled="disabled" v-model="spec1.part_attic.value" clearable />
                                    </el-form-item>
                                    <el-form-item prop="part_attic.unit">
                                        <el-select name="part_attic_unit" :disabled="disabled"
                                            v-model="spec1.part_attic.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id"
                                                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p> {{ $t('technical_height') }} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="technical_height.value">
                                        <el-input name="technical_height" type="number"
                                            :placeholder="$t('technical_height')" :disabled="disabled"
                                            v-model="spec1.technical_height.value" clearable />
                                    </el-form-item>
                                    <el-form-item prop="technical_height.unit">
                                        <el-select name="technical_height_unit" :disabled="disabled"
                                            v-model="spec1.technical_height.unit" class="ml-3" clearable>
                                            <el-option v-for="item in units.unit_1" :key="item.id"
                                                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <div>
                                <p>{{ $t('basement_presence') }}:</p>
                                <template>
                                    <el-radio :disabled="disabled" v-model="spec1.basement_presence.presence"
                                        v-for="(item, i) in dictionary.presence" :key="i"
                                        :id="`basement_presence${item.id}`" class="prim" :label="item.id" border>{{
                                        item.name_cyr }}</el-radio>
                                </template>
                            </div>
                            <div class="item" v-if="spec1.basement_presence.presence === 1">
                                <p>{{ $t('basement_merit') }}</p>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item prop="basement_presence.length">
                                            <el-input name="basement_presence_length" :disabled="disabled" type="number"
                                                v-model="spec1.basement_presence.length" :placeholder="$t('lengths')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="basement_presence.width">
                                            <el-input name="basement_presence_width" :disabled="disabled" type="number"
                                                v-model="spec1.basement_presence.width" :placeholder="$t('width')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="basement_presence.height">
                                            <el-input name="basement_presence_height" :disabled="disabled" type="number"
                                                v-model="spec1.basement_presence.height" :placeholder="$t('height')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="basement_presence.unit">
                                            <el-select name="basement_presence_unit" :disabled="disabled"
                                                v-model="spec1.basement_presence.unit" class="w-full" clearable>
                                                <el-option v-for="item in units.unit_1" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :md="8">

                            <label class="item">
                                <p> {{ $t('balance_value') }} </p>
                                <div class="flex">
                                    <el-form-item class="flex-auto w-full" prop="balance_value.price">
                                        <el-input type="number" name="balance_value_price"
                                            :placeholder="$t('balance_value')" :disabled="disabled"
                                            v-model="spec1.balance_value.price" clearable />
                                    </el-form-item>
                                    <el-form-item prop="balance_value.currency">
                                        <el-select name="balance_value_currency" :disabled="disabled"
                                            v-model="spec1.balance_value.currency" class="ml-3" clearable>
                                            <el-option v-for="item in dictionary.currency" :key="item.id"
                                                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </label>
                            <label class="item">
                                <p>{{ $t('foundation_type') }}</p>
                                <el-form-item prop="foundation_type">
                                    <el-select name="foundation_type" :disabled="disabled"
                                        v-model="spec1.foundation_type" class="w-full"
                                        :placeholder="$t('foundation_type')" clearable>
                                        <el-option v-for="item in dictionary.foundationtype" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('load_bearing') }} </p>
                                <el-form-item prop="load_bearing">
                                    <el-select name="load_bearing" :disabled="disabled" v-model="spec1.load_bearing"
                                        class="w-full" :placeholder="$t('load_bearing')" clearable>
                                        <el-option v-for="item in dictionary.loadbearing" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('coverings') }} </p>
                                <el-form-item prop="coverings">
                                    <el-select name="coverings" :disabled="disabled" v-model="spec1.coverings"
                                        class="w-full" :placeholder="$t('coverings')" clearable>
                                        <el-option v-for="item in dictionary.coverings" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('roofsection') }} </p>
                                <el-form-item prop="roofsection">
                                    <el-select name="roofsection" :disabled="disabled" v-model="spec1.roofsection"
                                        class="w-full" :placeholder="$t('roofsection')" clearable>
                                        <el-option v-for="item in dictionary.roofsection" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                        </el-col>
                    </el-row>
                </div>
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('divisions_number') }} </p>
                                <el-form-item prop="divisions_number">
                                    <el-input name="divisions_number" :disabled="disabled"
                                        :placeholder="$t('divisions_number')" type="number"
                                        v-model="spec1.divisions_number" clearable />
                                </el-form-item>
                            </label>
                            <div class="item">
                                <p>
                                    {{ $t('chok_count') }}
                                </p>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item prop="section_parameters.length">
                                            <el-input name="section_parameters_length" type="number"
                                                :disabled="disabled" v-model="spec1.section_parameters.length"
                                                :placeholder="$t('lengths')" clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="section_parameters.width">
                                            <el-input name="section_parameters_width" type="number" :disabled="disabled"
                                                v-model="spec1.section_parameters.width" :placeholder="$t('width')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="section_parameters.height">
                                            <el-input name="section_parameters_height" type="number"
                                                :disabled="disabled" v-model="spec1.section_parameters.height"
                                                :placeholder="$t('height')" clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="section_parameters.unit">
                                            <el-select name="section_parameters_unit" :disabled="disabled"
                                                v-model="spec1.section_parameters.unit" class="w-full" clearable>
                                                <el-option v-for="item in units.unit_1" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <label class="item">
                                <p> {{ $t('transverse_wall_step') }} </p>
                                <el-form-item prop="transverse_wall_step">
                                    <el-input name="transverse_wall_step" type="number"
                                        :placeholder="$t('transverse_wall_step')" :disabled="disabled"
                                        v-model="spec1.transverse_wall_step" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>{{ $t('longitudinal_wall') }}</p>
                                <el-form-item prop="longitudinal_wall">
                                    <el-input name="longitudinal_wall" type="number"
                                        :placeholder="$t('longitudinal_wall')" :disabled="disabled"
                                        v-model="spec1.longitudinal_wall" clearable />
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('longitudinal_wall_spacing') }} </p>
                                <el-form-item prop="longitudinal_wall_spacing">
                                    <el-input name="longitudinal_wall_spacing" type="number"
                                        :placeholder="$t('longitudinal_wall_spacing')" :disabled="disabled"
                                        v-model="spec1.longitudinal_wall_spacing" clearable />
                                </el-form-item>
                            </label>
                            <div class="item">
                                <p> {{ $t('seismicbelts') }} </p>
                                <template>
                                    <el-form-item prop="seismicbelts">
                                        <el-radio :disabled="disabled" v-for="(item, i) in dictionary.beltspresence"
                                            :key="i" v-model="spec1.seismicbelts" class="prim"
                                            :id="`seismicbelts${item.id}`" :label="item.id" border>{{ item.name_cyr }}
                                        </el-radio>
                                    </el-form-item>
                                </template>
                            </div>
                            <div class="item">
                                <p> {{ $t('connectingbelts') }} </p>
                                <template>
                                    <el-form-item prop="connectingbelts">
                                        <el-radio :disabled="disabled" v-for="(item, i) in dictionary.beltspresence"
                                            :key="i" v-model="spec1.connectingbelts" :id="`connectingbelts${item.id}`"
                                            class="prim" :label="item.id" border>{{ item.name_cyr }}
                                        </el-radio>
                                    </el-form-item>
                                </template>
                            </div>
                        </el-col>
                        <el-col :md="8">
                            <div class="item">
                                <p> {{ $t('window_betwee_length') }} </p>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item prop="window_spacing.length">
                                            <el-input type="number" name="window_spacing_length" :disabled="disabled"
                                                v-model="spec1.window_spacing.length" :placeholder="$t('lengths')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="window_spacing.width">
                                            <el-input type="number" name="window_spacing_width" :disabled="disabled"
                                                v-model="spec1.window_spacing.width" :placeholder="$t('width')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="window_spacing.height">
                                            <el-input type="number" name="window_spacing_height" :disabled="disabled"
                                                v-model="spec1.window_spacing.height" :placeholder="$t('height')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="window_spacing.unit">
                                            <el-select name="window_spacing_unit" :disabled="disabled"
                                                v-model="spec1.window_spacing.unit" class="w-full" clearable>
                                                <el-option v-for="item in units.unit_1" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="item">
                                <p> {{ $t('walls_length') }} </p>
                                <el-row :gutter="20">
                                    <el-col :span="6">
                                        <el-form-item prop="curtains.length">
                                            <el-input name="curtains_lenght" type="number" :disabled="disabled"
                                                v-model="spec1.curtains.length" :placeholder="$t('lengths')"
                                                clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="curtains.width">
                                            <el-input name="curtains_width" type="number" :disabled="disabled"
                                                v-model="spec1.curtains.width" :placeholder="$t('width')" clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="curtains.height">
                                            <el-input name="curtains_height" type="number" :disabled="disabled"
                                                v-model="spec1.curtains.height" :placeholder="$t('height')" clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item prop="curtains.unit">
                                            <el-select name="curtains_unit" :disabled="disabled"
                                                v-model="spec1.curtains.unit" class="w-full" clearable>
                                                <el-option v-for="item in units.unit_1" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('concrete_class') }} </p>
                                <el-form-item prop="concrete_class">
                                    <el-select name="concrete_class" :disabled="disabled" v-model="spec1.concrete_class"
                                        class="w-full" :placeholder="$t('concrete_class')" clearable>
                                        <el-option v-for="item in dictionary.concreteclass" :key="item.id"
                                            :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('brick_brand') }} </p>
                                <el-form-item prop="brick_brand">
                                    <el-select name="brick_brand" :disabled="disabled" v-model="spec1.brick_brand"
                                        class="w-full" :placeholder="$t('brick_brand')" clearable>
                                        <el-option v-for="item in dictionary.brickbrand" :key="item.id"
                                            :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('mixture_class') }} </p>
                                <el-form-item prop="mixture_class">
                                    <el-select name="mixture_class" :disabled="disabled" v-model="spec1.mixture_class"
                                        class="w-full" :placeholder="$t('mixture_class')" clearable>
                                        <el-option v-for="item in dictionary.mixtureClasslist" :key="item.id"
                                            :label="item.name" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('soil_type') }} </p>
                                <el-form-item prop="soil_type">
                                    <el-select name="soil_type" :disabled="disabled" v-model="spec1.soil_type"
                                        class="w-full" :placeholder="$t('soil_type')" clearable>
                                        <el-option v-for="item in dictionary.soiltype" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <div class="item">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <p> {{ $t('armature_class_armatureclass') }} </p>
                                        <el-form-item class="mb-0" prop="armature_class.armatureclass">
                                            <el-select name="armature_class_armatureclass" :disabled="disabled"
                                                v-model="spec1.armature_class.armatureclass"
                                                :placeholder="$t('armature_class_armatureclass')" class="w-full"
                                                clearable>
                                                <el-option v-for="item in dictionary.armatureclass" :key="item.id"
                                                    :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <p> {{ $t('armature_class_armaturediametr') }} </p>
                                        <el-form-item class="mb-0" prop="armature_class.armaturediametr">
                                            <el-select name="armature_class_armaturediametr" :disabled="disabled"
                                                v-model="spec1.armature_class.armaturediametr" class="w-full"
                                                :placeholder="$t('armature_class_armaturediametr')" clearable>
                                                <el-option v-for="item in dictionary.armaturediametr" :key="item.id"
                                                    :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <label class="item">
                                <p> {{ $t('steel_resistance') }} </p>
                                <el-form-item prop="steel_resistance">
                                    <el-input type="number" name="steel_resistance"
                                        :placeholder="$t('steel_resistance')" :disabled="disabled"
                                        v-model="spec1.steel_resistance" />
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('brick_or_stone_wall_type') }} </p>
                                <el-form-item prop="brick_or_stone_wall_type">
                                    <el-select name="brick_or_stone_wall_type" :disabled="disabled"
                                        v-model="spec1.brick_or_stone_wall_type"
                                        :placeholder="$t('brick_or_stone_wall_type')" class="w-full" clearable>
                                        <el-option v-for="item in 3" :key="item.id" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('determination_endurance_reserve') }} </p>
                                <el-form-item prop="determination_endurance_reserve">
                                    <el-input name="determination_endurance_reserve" type="number"
                                        :placeholder="$t('determination_endurance_reserve')" :disabled="disabled"
                                        v-model="spec1.determination_endurance_reserve" />
                                </el-form-item>
                            </label>
                        </el-col>
                    </el-row>
                </div>
                <div class="input-section">
                    <el-row :gutter="30">
                        <el-col :md="8">
                            <label class="item">
                                <p> {{ $t('projection_projecttype') }} </p>
                                <el-form-item prop="projection.projecttype">
                                    <el-select name="projection_projecttype" :disabled="disabled"
                                        v-model="spec1.projection.projecttype"
                                        :placeholder="$t('projection_projecttype')" class="w-full" clearable>
                                        <el-option v-for="item in dictionary.projecttype" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item" v-if="spec1.projection.projecttype === 2">
                                <p> {{ $t('projection_number') }} </p>
                                <el-form-item prop="projection.number">
                                    <el-input name="projection_number" :disabled="disabled"
                                        placeholder="$t('projection_number')" type="number"
                                        v-model="spec1.projection.number" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('project_organization_name') }} </p>
                                <el-form-item prop="project_organization_name">
                                    <el-input name="project_organization_name" :disabled="disabled"
                                        :placeholder="$t('project_organization_name')"
                                        v-model="spec1.project_organization_name" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('properties') }} </p>
                                <el-form-item prop="properties">
                                    <el-select name="properties" :disabled="disabled" v-model="spec1.properties"
                                        class="w-full" :placeholder="$t('properties')" multiple>
                                        <el-option v-for="item in dictionary.properties" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p class="mTitle"> {{ $t('reconstructionsecondstep_finalconclusion') }} </p>
                                <el-form-item prop="finalconclusion">
                                    <el-select name="reconstructionsecondstep_finalconclusion" :disabled="disabled"
                                        v-model="spec1.finalconclusion" class="final mt-3 w-full"
                                        :placeholder="$t('reconstructionsecondstep_finalconclusion')" clearable>
                                        <el-option v-for="item in dictionary.finalconclusion" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p>{{ $t('seismicity_area') }}</p>
                                <el-form-item prop="seismicity_area">
                                    <el-input name="seismicity_area" :disabled="disabled"
                                        :placeholder="$t('seismicity_area')" v-model="spec1.seismicity_area"
                                        clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('construction_installation_works') }} </p>
                                <el-form-item prop="construction_installation_works">
                                    <el-input name="construction_installation_works" :disabled="disabled"
                                        :placeholder="$t('construction_installation_works')"
                                        v-model="spec1.construction_installation_works" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p>
                                    {{ $t('communication_engineering') }}
                                </p>
                                <el-form-item prop="communication_engineering">
                                    <el-select name="communication_engineering" :disabled="disabled"
                                        v-model="spec1.communication_engineering" class="w-full"
                                        :placeholder="$t('communication_engineering')" clearable multiple>
                                        <el-option v-for="item in dictionary.communicationengineering" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                        </el-col>
                        <el-col :md="8">
                            <label class="item">
                                <p>{{ $t('robustness_reliability') }} </p>
                                <el-form-item prop="robustness_reliability">
                                    <el-input name="robustness_reliability" :disabled="disabled"
                                        :placeholder="$t('robustness_reliability')"
                                        v-model="spec1.robustness_reliability" clearable />
                                </el-form-item>
                            </label>
                            <label class="item">
                                <p> {{ $t('dailycondition') }} </p>
                                <el-form-item prop="dailycondition">
                                    <el-select name="dailycondition" :disabled="disabled" v-model="spec1.dailycondition"
                                        class="w-full" :placeholder="$t('dailycondition')" clearable>
                                        <el-option v-for="item in dictionary.dailycondition" :key="item.id"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </label>
                            <div class="item border-t-2 pt-5" :class="errors.file1null ? 'file-error' : ''">
                                <el-upload :disabled="disabled" class="upload-demo" ref="upload1" action="#"
                                    :on-preview="handlePreview" :before-upload="beforeUpload1"
                                    :before-remove="beforeRemove1" multiple :file-list="file1List">
                                    <div class="upload-field flex justufy-between text-left">
                                        <el-button id="have_document_file" class="primary-btn h-8"
                                            size="small">{{ $t('choose_file') }}</el-button>
                                        <p class="ml-5 text-base">
                                            {{ $t('have_document_file') }}
                                        </p>
                                    </div>
                                </el-upload>
                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                            </div>
                            <div class="item border-t-2 pt-5" :class="errors.file2null ? 'file-error' : ''">
                                <el-upload :disabled="disabled" class="upload-demo" ref="upload2"
                                    :on-preview="handlePreview" action="#" :before-upload="beforeUpload2"
                                    :before-remove="beforeRemove2" multiple :file-list="file2List">
                                    <div class="upload-field flex justufy-between text-left">
                                        <el-button id="please_choose_file" class="primary-btn h-8"
                                            size="small">{{ $t('choose_file') }}</el-button>
                                        <p class="ml-5 text-base"> {{ $t('building_dal') }} </p>
                                    </div>
                                </el-upload>
                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                            </div>
                            <div class="item mt-4">
                                <div class="actions">
                                    <div class="primary">
                                        <!--   <el-button v-if="spec1.id" class="primary-btn"
                        ><i class="el-icon-edit mr-1"></i>Ўзгартириш</el-button
                      > -->
                                        <el-button class="primary-btn" id="savespec1"
                                            v-if="role != 'operator' && !disabled" @click="savespec1()"><i
                                                class="el-icon-document-checked mr-1"></i> {{ $t('save') }}
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <el-row class="flex items-end" :gutter="20">
                <el-col :span="8"></el-col>
                <el-col class="mt-5" :span="8"></el-col>
                <el-col class="mt-10" :span="8">
                    <div v-if="CurYear >= 30">
                        <el-button v-if="'id' in spec1" class="primary-btn w-full"
                            @click.native="$router.push({ name: 'reconstruction', query: { id: $route.query.id } })">
                            <i class="el-icon-more mr-2"></i> {{ $t('building_last_data') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div  v-if="modal"  class="modal fs-14" :class="modal?'active':''">
            <div class="modal-cover"></div>
            <div class="modal-item padding-map" style="width:95%; height:95%;">
                <div class="flex justify-between modal-absolute-header">
                    <p class="modal-title "> {{ disabled ? `${$t('show_coor')}:` : `${$t('change_coor')}:` }}</p>
                    <button style="font-size:16px; " @click="modal = false"><i class="el-icon-close"
                            style="font-weight:600;"></i>
                    </button>
                </div>
                <div class="w-full map-item h-full">
                    <yandex-map @click="clicked" :coords="coords" style="height:600px; width: 100%" :zoom="14"
                        :controls="['zoomControl']">
                        <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                    </yandex-map>
                </div>
                <div class="flex w-full justify-between modal-bottom fw-w">
                    <div class="flex items-center">
                        <p class="flex items-senter" style="font-size:16px;"><b class="mr-2">{{ $t('close') }}:</b>
                            {{ coords.join() }} </p>
                    </div>
                    <div class="modal-inputes flex justify-center">
                        <button id="closeMap" @click="modal = false" style="padding:15px 15px;"> <i
                                class="el-icon-close mr-2"></i> {{ $t('close') }}</button>
                        <button id="SaveEditMap" v-if="!disabled" class="ml-2"
                            style="padding:15px 15px; background:green; " @click="SaveEditMap()"> <i
                                class="el-icon-document-checked mr-2"></i> {{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <el-dialog :title="$t('unattested_reject_warn')" :visible.sync="reject_info.modal" width="400px">
            <div class="w-full">
                <p class="text-sm font-bold mb-0.5">{{ $t('cancel_issue') }}</p>
                <el-input type="textarea" :rows="4" placeholder="..."
                    v-model="reject_info.description"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reject_info.modal = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="setStatus('rejected', reject_info.id)">{{ $t('save') }}</el-button>
            </span>
        </el-dialog>

    </div>
    <div class="fixed-item" v-else v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="fixed-cover"></div>
        <div class="about-knumber">
            <button class="mt-3">
                <iconInfo />
            </button>
            <button class="mt-3">
                <iconQuestion />
            </button>
        </div>
        <div class="pr kadaster-item">
            <div class="erorrsk" v-if="knumber == 2">
                <p>{{ $t('no_cadaster_number') }}</p>
            </div>
            <div class="uncompletek" v-if="knumber == 3">
                <p>{{ $t('uncomplate_cadaster_data') }}</p>
            </div>
            <div class="succesk" v-if="knumber == 4">
                <p>{{ $t('cadaster_number_succes_find') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 5">
                <p> {{ $t('building_have_server') }} </p>
            </div>
            <div class="erorrsk" v-if="knumber == 7">
                <p> {{ $t('error_ser') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 8">
                <p>{{ $t('error_cadaster_ser') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 9">
                <p>{{ $t('none_object_for_building') }}</p>
            </div>
            <!--<span style="color:#fff; font-size:16px;">{{a}}</span> -->
            <div class="pb-1">
                <span style="color:#fff; font-size:16px;"> {{ $t('add_building_cadaster') }} </span>
            </div>
            <div class="pb-3 mb-4">
                <div class="flex items-center gap-1">
                    <el-input name="k-number" style="min-width:400px;" class="k-input"
                        :class="knumber == 1 || knumber == 5 || knumber == 8 || knumber == 2 ? 'error' : knumber == 3 ? 'connecting' : knumber == 4 ? 'succes' : ''"
                        v-model="spec1.cadastral_number" :placeholder="`${$t('example')}: 10:01:01:01:01:1111:0001`"
                        clearable />
                    <button class="px-3 py-2 rounded-lg bg-green-600 text-white"
                        @click="CheckKadastor()">Текшириш</button>
                </div>
                <p v-if="knumber == 1" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('uncomplate_cadaster_number') }}</p>
                <p v-if="knumber == 2" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('noregister_cadaster_number') }}</p>
                <p v-if="knumber == 9" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('none_object_building') }} </p>
                <p v-if="knumber == 8" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('error_with_cadaster_server') }} </p>
                <p v-if="knumber == 5" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_number_have_in') }}</p>
                <p v-if="knumber == 7" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('error_with_server') }} </p>
                <p v-if="knumber == 3" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_find_uncomplate_data') }}</p>
                <p v-if="knumber == 4" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_number_find_succes') }}</p>
            </div>
            <div class=" flex justify-end">
                <button id="cancel" class="kt-button" style="background:#004787; color:#fff;" @click="$router.go(-1)">
                    {{ $t('cancel') }}</button>
                <button id="continue_add" class="kt-button ml-1 bg10" v-if="knumber == 3" style=" color:#fff;"
                    @click="knumber = 6"><i class="el-icon-document mr-1"></i> {{ $t('continue_add') }}</button>
                <button id="continue_add" class="kt-button ml-1 bg10" v-if="knumber == 4" style=" color:#fff;"
                    @click="knumber = 6"> <i class="el-icon-document-checked mr-1"></i> {{ $t('continue_add') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import districts from '@/assets/files/districts.json'
import Moment from 'moment';
import InvestmentSwitch from "@/components/InvestmentSwitch";

// ICONS
import iconInfo from './Icons/iconInfo.vue';
import iconQuestion from './Icons/iconQuestion.vue';

import {
    extendMoment
} from 'moment-range';
const moment = extendMoment(Moment);
export default {
    props: {
        geo: '',
        cadastral_number: ''

    },
    components: { InvestmentSwitch, iconInfo, iconQuestion },
    data() {
        return {
            visibleApprove: false,
            visibleReject: false,
            knumber: 0,
            a: '',
            error: {},
            pickerOptions: {
                disabledDate(date) {
                    return date < new Date('2021');
                },
            },
            objInfo: {},
            loading: false,
            disabled: false,
            CurYear: 0,
            generalinformation: {},
            errors: {
                generalinformation: false,
                cadastral_number: false,
                region: false,
                district: false,
                address: false,
                year_construction: false,
                numberfloors: false,
                building_group: false,
                neighborhood: false,
                neighborhood_id: false,
                file1null: false,
                file2null: false
            },
            spec1: {
                generalinformation: this.$route.params.id,
                cadastral_number: this.cadastral_number,
                name_uz: "",
                name_ru: "",
                name_cyr: "",
                region: null,
                district: null,
                building_group: null,
                neighborhood: '',
                neighborhood_id: null,
                address: "",
                year_construction: null,
                buildingseismicity: null,
                geolocation: null,
                numberfloors: null,
                constructivesolution: null,
                plannedshape: null,
                buildingseismicity: [{
                    required: true,
                    message: this.$t('choose_buildingseismicity'),
                    trigger: "change"
                },],
                overall_size: {
                    length: null,
                    width: null,
                    height: null,
                    unit: 1,
                },
                floors_height: {
                    value: null,
                    unit: 1,
                },
                part_attic: {
                    value: null,
                    unit: 1,
                },
                technical_height: {
                    value: null,
                    unit: 1,
                },
                basement_presence: {
                    length: null,
                    height: null,
                    width: null,
                    presence: null,
                    unit: 1,
                },
                divided_into_sections: null,
                balance_value: {
                    price: "",
                    currency: 1,
                },
                foundation_type: null,
                load_bearing: null,
                coverings: null,
                roofsection: null,
                divisions_number: null,
                section_parameters: {
                    length: null,
                    width: null,
                    height: null,
                    unit: 1,
                },
                transverse_wall_step: null,
                longitudinal_wall: null,
                longitudinal_wall_spacing: null,
                seismicbelts: null,
                connectingbelts: null,
                window_spacing: {
                    length: null,
                    width: null,
                    height: null,
                    unit: 1,
                },
                curtains: {
                    length: null,
                    width: null,
                    height: null,
                    unit: 1,
                },
                concrete_class: null,
                brick_brand: null,
                mixture_class: null,
                soil_type: null,
                armature_class: {
                    armatureclass: null,
                    armaturediametr: null,
                },
                steel_resistance: "",
                brick_or_stone_wall_type: "",
                determination_endurance_reserve: "",
                projection: {
                    number: null,
                    projecttype: null,
                },
                project_organization_name: "",
                construction_installation_works: "",
                properties: [],
                seismicity_area: "",
                communication_engineering: [],
                robustness_reliability: "",
                dailycondition: null,
                finalconclusion: null,
            },
            rules1: {
                cadastral_number: [{
                    required: true,
                    message: this.$t('please_add_cadastrel_number'),
                    trigger: "change",
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                name_cyr: [{
                    required: true,
                    message: this.$t('please_add_building_name'),
                    trigger: "change",
                },],
                region: [{
                    required: true,
                    message: this.$t('choose_region'),
                    trigger: "change"
                },],
                district: [{
                    required: true,
                    message: this.$t('choose_district'),
                    trigger: "change",
                },],
                address: [{
                    required: true,
                    message: this.$t('choose_adress'),
                    trigger: "change",
                },],
                numberfloors: [{
                    required: true,
                    message: this.$t('choose_numberfloors'),
                    trigger: "change",
                },],
                year_construction: [{
                    required: true,
                    message: this.$t('choose_year_construction'),
                    trigger: "change",
                },],

                geolocation: [{
                    required: true,
                    message: this.$t('choose_geolocation'),
                    trigger: "change",
                },],
                floors_height: {
                    value: [{
                        required: true,
                        message: this.$t('choose_floors_height_value'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                constructivesolution: [{
                    required: true,
                    message: this.$t('choose_constructivesolution'),
                    trigger: "change",
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                plannedshape: [{
                    required: true,
                    message: this.$t('choose_plannedshape'),
                    trigger: "change",
                },],
                numberfloors: [{
                    required: true,
                    message: this.$t('choose_numberfloors'),
                    trigger: "change",
                },],
                overall_size: {
                    length: [{
                        required: true,
                        message: this.$t('choose_overall_size_length'),
                        trigger: "change",
                    },],
                    width: [{
                        required: true,
                        message: this.$t('choose_overall_size_width'),
                        trigger: "change",
                    },],
                    height: [{
                        required: true,
                        message: this.$t('choose_overall_size_height'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                floors_height: {
                    value: [{
                        required: true,
                        message: this.$t('file_secsess_send'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                part_attic: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                technical_height: {
                    value: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                basement_presence: {
                    presence: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                divided_into_sections: [{
                    required: true,
                    message: this.$t('choose_divided_into_sections'),
                    trigger: "change",
                },],
                balance_value: {
                    price: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                    currency: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                foundation_type: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                load_bearing: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                coverings: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change"
                },],
                roofsection: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change"
                },],
                divisions_number: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                section_parameters: {
                    length: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    },],
                    width: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    height: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                transverse_wall_step: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                longitudinal_wall: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                longitudinal_wall_spacing: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                seismicbelts: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                connectingbelts: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                window_spacing: {
                    length: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    },],
                    width: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    height: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                curtains: {
                    length: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    },],
                    width: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    height: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    }],
                    unit: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                concrete_class: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change"
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                brick_brand: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                mixture_class: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                soil_type: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                armature_class: {
                    armatureclass: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                    armaturediametr: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    },],
                },
                steel_resistance: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                brick_or_stone_wall_type: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                determination_endurance_reserve: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                projection: {
                    number: [{
                        message: this.$t('please_enter_data'),
                        trigger: "change"
                    },],
                    projecttype: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },],
                },
                project_organization_name: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                construction_installation_works: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                properties: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                seismicity_area: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                communication_engineering: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                robustness_reliability: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
                dailycondition: [{
                    required: true,
                    message: this.$t('choose_dailycondition'),
                    trigger: "change",
                },],
                finalconclusion: [{
                    required: true,
                    message: this.$t('please_enter_data'),
                    trigger: "change",
                },],
            },
            current_district: [],
            obj: {},
            file1List: [],
            file2List: [],
            files1: [],
            files2: [],
            activeNames: '1',
            coords: [41.31629324503914, 69.26717199864542],
            coor: null,
            modal: false,
            textarea1: null,
            value: null,
            value1: null,
            value3: null,
            loading: false,
            file1Name: null,
            file2Name: null,
            districts: districts,
            disableds: [false, true, true, true, true],
            reject_info: {
                modal: false,
                id: '',
                action: '',
                description: ''
            }
        };
    },
    computed: {
        isCreated() {
            return !!this.$route.query.id;
        },
        file1Url() {
            return axios.defaults.baseURL + "dictionary/filetechnicaldoc/";
        },
        file2Url() {
            return axios.defaults.baseURL + "dictionary/deedfile/";
        },
        role() {
            let a = this.$store.state.role;
            if (a == 'operator') {
                this.disabled = true;
            }
            if (a == 'admin') {
                this.disableds = [false, false, false, false, false]
            }

            return a
        },
        dictionary() {
            return this.$store.state.dictionary;
        },
        activer() {
            return this.$store.state.activeName
        },
        disabletItem() {
            return this.$store.state.disabletItem
        },
        units() {
            return this.$store.state.units;
        },
    },
    watch: {
        cadastral_number() {
            this.spec1.cadastral_number = this.cadastral_number
        },
        file1List() {
            if (this.file1List.length > 0) {
                this.errors.file1null = false
            }
        },
        file2List() {
            if (this.file2List.length > 0) {
                this.errors.file2null = false
            }
        }

    },
    mounted() {
        this.activeNames = this.activer
        if (this.$route.params.edit == '0') {
            this.disabled = true
        } else {
            this.disabled = false
        }

        // if (this.role == 'admin') {
        //     this.disabled = true
        // }
        if (this.disabled) {
            this.activeNames = ['1']
        }
        this.$store.commit('set_activename', ['1'])
        this.__GET();

        if (this.$route.query.id) {
            this.knumber = 6
        }
    },
    methods: {
        setStatus(action, id) {
            let data = {
                id: id,
                status: action === 'approved' ? 'approved' : 'rejected'
            };
            if (action === 'approved') {
                this.$notify({
                    title: this.$t('approve_n'),
                    type: 'success'
                });
            } else if (action === 'rejected') {
                this.$notify.info({
                    title: this.$t('unattested_rejected_n'),
                });
                data.description = this.reject_info.description
            }



            axios.patch(`/building/status/`, data);
            this.$router.go(-2);
            this.visibleApprove = false;
            this.visibleReject = false;
        },
        __GET() {
            if (this.isCreated) {
                this.loading = true;
                if (this.$route.query.id) {
                    axios.get(`/building/specification/${this.$route.query.id}/`)
                        .then((response) => {
                            this.spec1 = response.data;
                            if (this.spec1.overall_size == null) {
                                this.spec1.overall_size = {
                                    length: null,
                                    width: null,
                                    height: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.floors_height == null) {
                                this.spec1.floors_height = {
                                    value: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.part_attic == null) {
                                this.spec1.part_attic = {
                                    value: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.technical_height == null) {
                                this.spec1.technical_height = {
                                    value: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.basement_presence == null) {
                                this.spec1.basement_presence = {
                                    length: null,
                                    height: null,
                                    width: null,
                                    presence: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.balance_value == null) {
                                this.spec1.balance_value = {
                                    price: "",
                                    currency: 1,
                                }
                            }
                            if (this.spec1.section_parameters == null) {
                                this.spec1.section_parameters = {
                                    length: null,
                                    width: null,
                                    height: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.window_spacing == null) {
                                this.spec1.window_spacing = {
                                    length: null,
                                    width: null,
                                    height: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.curtains == null) {
                                this.spec1.curtains = {
                                    length: null,
                                    width: null,
                                    height: null,
                                    unit: 1,
                                }
                            }
                            if (this.spec1.armature_class == null) {
                                this.spec1.armature_class = {
                                    armatureclass: null,
                                    armaturediametr: null,
                                }
                            }
                            if (this.spec1.projection == null) {
                                this.spec1.projection = {
                                    number: null,
                                    projecttype: null,
                                }
                            }
                            this.coor = response.data.geolocation.join();
                            this.CurrentYear(response.data.created_at);
                            this.spec1.year_construction = String(
                                response.data.year_construction
                            );
                        })
                        .finally(() => {
                            this.loading = false
                        })
                    this.getFiles();
                }
            }
        },
        CurrentYear(a) {
            a = a.split(".")

            a = a[2] + "-" + a[1] + "-" + a[0]
            var b = new Date()
            b = b.getFullYear() + "-" + String(b.getMonth() + 1).padStart(2, '0') + "-" + String(b.getDate()).padStart(2, '0');;
            const start = moment(a);
            const end = moment(b);
            const range = moment.range(start, end);
            this.CurYear = parseInt(range.diff('days'));
        },
        getFiles(val) {
            if (val === 1) {
                axios
                    .get(
                        `/dictionary/filetechnicaldoclist/?technical_document_file=${this.$route.query.id}`
                    )
                    .then((response) => {
                        this.file1List = response.data;
                    });
            } else if (val === 2) {
                axios
                    .get(`/dictionary/deedfilelist/?deed_file=${this.$route.query.id}`)
                    .then((response) => {
                        this.file2List = response.data;
                    });
            } else {
                axios
                    .get(
                        `/dictionary/filetechnicaldoclist/?technical_document_file=${this.$route.query.id}`
                    )
                    .then((response) => {
                        this.file1List = response.data;
                    });
                axios
                    .get(`/dictionary/deedfilelist/?deed_file=${this.$route.query.id}`)
                    .then((response) => {
                        this.file2List = response.data;
                    });
            }
        },
        beforeUpload1(file) {
            if (this.$route.query.id && this.$route.query.id != undefined) {
                let formData = new FormData();
                formData.append("name", file.name);
                formData.append("file", file);
                formData.append("technical_document_file", this.$route.query.id);
                axios.post(this.file1Url, formData).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('file_secsess_send'),
                        timer: 3000
                    })
                    this.getFiles(1);
                });
            } else {
                this.file1List.push({
                    name: file.name,
                    file: file
                })
                this.files1.push({
                    name: file.name,
                    file: file
                })
            }
        },
        beforeUpload2(file) {
            if (this.$route.query.id && this.$route.query.id != undefined) {
                let formData = new FormData();
                formData.append("name", file.name);
                formData.append("file", file);
                formData.append("deed_file", this.$route.query.id);
                axios.post(this.file2Url, formData).then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('file_secsess_send'),
                        timer: 3000
                    })
                    this.getFiles(2);
                });
            } else {
                this.file2List.push({
                    name: file.name,
                    file: file
                })
                this.files2.push({
                    name: file.name,
                    file: file
                })
            }
        },
        uploadFile(files, i, id, link, link_name) {
            if (i < files.length && i != files.length) {
                let formData = new FormData();
                formData.append('name', files[i].name)
                formData.append('file', files[i].file)
                formData.append(link_name, id)
                axios.post(link, formData)
                    .then((response) => {
                        // this.getFiles(link);
                    })
                i = i + 1;
                this.uploadFile(files, i, id, link, link_name)
            } else {
                return 0
            }

        },
        savespec1() {
            this.$refs.spec1.validate((valid) => {
                if (typeof this.spec1.geolocation == 'string') {
                    this.spec1.geolocation = this.spec1.geolocation.split(",")
                }
                if (valid && this.file1List.length > 0 && this.file2List.length > 0) {
                    if (this.$route.query.id && this.$route.query.id != undefined) {
                        this.loading = true;
                        axios.patch(`/building/specification/update/${this.$route.query.id}/`, this.spec1)
                            .then((response) => {
                                this.__GET();
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                    .then(() => {
                                        if (this.$route.params.obj) {
                                            this.$router.push({
                                                path: `/objects/building-list/${this.$route.params.obj}`
                                            })
                                        } else {
                                            this.$router.push({
                                                path: `/building-list/new`
                                            })
                                        }
                                    })

                            })
                            .catch((error) => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        this.loading = true;
                        axios.post(`/building/specification/`, this.spec1)
                            .then((response) => {

                                this.__GET();
                                this.uploadFile(this.files1, 0, response.data.id, this.file1Url, 'technical_document_file')
                                this.uploadFile(this.files2, 0, response.data.id, this.file2Url, 'deed_file')
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                    .then(() => {
                                        if (this.$route.params.obj) {
                                            this.$router.push({
                                                path: `/objects/building-list/${this.$route.params.obj}`
                                            })
                                        } else {
                                            this.$router.push({
                                                path: `/building-list/new`
                                            })
                                        }
                                    })

                            })
                            .catch((error) => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }

                } else {
                    if (this.file1List.length == 0) {
                        this.errors.file1null = true
                    }
                    if (this.file2List.length == 0) {
                        this.errors.file2null = true
                    }
                }
            })
        },
        regionChanged() {
            this.current_district = [];
            this.spec1.district = [];
        },
        handlePreview(file) {
            // window.open(file.file)
            const url = file.file
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "a")
            document.body.appendChild(link)
            link.click()
        },
        beforeRemove1(file, fileList) {
            if (this.$route.query.id && this.$route.query.id != undefined) {
                return this.$confirm(`${this.$t('do_delete_file')} ${file.name} ?`).then(
                    () => {
                        axios
                            .delete(`/dictionary/filetechnicaldocdestroy/${file.id}/`)
                            .then((response) => {
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('file_secsess_send'),
                                    timer: 3000
                                })
                                this.getFiles()
                            })
                            .catch((error) => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_delete_file'),
                                    timer: 3000
                                });
                            });
                    }
                );
            }
            else {
                var index = -1
                for (let i = 0; i < this.file1List.length; i++) {
                    if (this.file1List[i].name == file.name) {
                        index = i
                    }
                }
                if (index != -1) {
                    this.file1List.splice(index, 1);
                    this.files1.splice(index, 1);
                }
            }
        },
        beforeRemove2(file, fileList) {
            if (this.$route.query.id && this.$route.query.id != undefined) {
                return this.$confirm(`${this.$t('do_delete_file')} ${file.name} ?`).then(
                    () => {
                        axios
                            .delete(`/dictionary/deedfiledestroy/${file.id}/`)
                            .then((response) => {
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('file_secsess_send'),
                                    timer: 3000
                                })
                                this.getFiles()
                            })
                            .catch((error) => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_delete_file'),
                                    timer: 3000
                                });
                            });
                    }
                );
            }
            else {
                var index = -1
                for (let i = 0; i < this.file2List.length; i++) {
                    if (this.file2List[i].name == file.name) {
                        index = i
                    }
                }
                if (index != -1) {
                    this.file2List.splice(index, 1);
                    this.files2.splice(index, 1);
                }
            }
        },
        CheckKadastor() {
            if (this.spec1.cadastral_number.length == 0) {
                this.knumber = 0
            }
            else if (this.spec1.cadastral_number.length < 15) {
                this.knumber = 1
            }
            else {
                this.loading = true
                var data = {
                    type: "building",
                    cad_num: this.spec1.cadastral_number
                }
                this.erors = []
                axios.get(`/building/cadastral/${this.spec1.cadastral_number}/`)
                    .then(res => {
                        this.a = res.data
                        if (res.data.result || res.data.object == null) {
                            this.loading = false
                            if (!res.data.object && res.data.result == 0) {
                                this.knumber = 9
                            } else {
                                this.knumber = 5
                            }

                        } else {
                            axios.get(`/building/generalinformation/${res.data.object}/`)
                                .then((response) => {
                                    this.generalinformation = response.data
                                });
                            this.spec1.generalinformation = res.data.object
                            axios.post('/dictionary/insert/cadastr/', data)
                                .then((res) => {
                                    if (res.data.result == 0) {
                                        this.knumber = 2
                                        this.loading = false
                                        this.error.message = res.data.message
                                    } else {

                                        if (res.data.objects) {
                                            var b = res.data.objects.filter((item) => {
                                                return item.cadastral_number_object === this.spec1.cadastral_number
                                            })
                                            if (b.length > 0) {
                                                // name

                                                // Street
                                                if (res.data.address) {
                                                    var address = res.data.address.split(",")
                                                    if (address.length > 3) {
                                                        this.spec1.address = address[2] + address[3]
                                                    }
                                                    if (address.length > 1) {
                                                        this.spec1.address = address[0] + address[1]
                                                    } else {
                                                        address = res.data.address.split(".")
                                                        if (address.length > 2) {
                                                            this.spec1.address = address[2]
                                                        } else {
                                                            this.spec1.address = res.data.address
                                                        }
                                                    }
                                                    this.errors.adress = false

                                                } else {
                                                    this.errors.adress = true
                                                }
                                                // Region
                                                this.spec1.region = res.data.region_id
                                                // District
                                                axios.get(`/dictionary/district/?region__shared_id=${this.spec1.region}`)
                                                    .then((response) => {
                                                        this.current_district = response.data;
                                                        this.spec1.district = res.data.district_id
                                                    })
                                                // Neighboard
                                                if (res.data.neighborhood) {
                                                    this.spec1.neighborhood = res.data.neighborhood
                                                    this.erors.neighborhood = false

                                                } else {
                                                    this.erors.neighborhood = true
                                                }
                                                if (res.data.neighborhood_id) {
                                                    this.spec1.neighborhood_id = res.data.neighborhood_id
                                                    this.erors.neighborhood_id = false
                                                } else {
                                                    this.erors.neighborhood_id = true
                                                }
                                                // numberfloors
                                                if (b[0].numberfloors) {
                                                    this.spec1.numberfloors = parseInt(b[0].numberfloors)
                                                    this.erors.numberfloors = false

                                                } else {
                                                    this.erors.numberfloors = true
                                                }

                                                // date building
                                                if (res.data.year) {
                                                    var date = res.data.year.split(".")
                                                    if (date.length > 1) {
                                                        this.spec1.year_construction = date[2]
                                                    } else {
                                                        date = res.data.year.split("-")
                                                        this.spec1.year_construction = date[0]
                                                    }
                                                    this.errors.year_construction = true
                                                } else {
                                                    this.errors.year_construction = false
                                                }
                                                this.knumber = 4
                                                if (!this.errors.year_construction) {
                                                    this.knumber = 3
                                                }
                                                this.loading = false
                                            } else {
                                                this.knumber = 2
                                                this.loading = false
                                                if (res.data.message) {
                                                    this.errors.message = res.data.message
                                                }

                                            }
                                        } else {
                                            this.knumber = 2
                                            this.loading = false
                                            if (res.data.message) {
                                                this.errors.message = res.data.message
                                            }
                                        }

                                    }

                                })
                                .catch(e => {
                                    this.loading = false
                                    this.knumber = 8
                                })
                        }

                    })
                    .catch(e => {
                        this.knumber = 7
                        this.loading = false
                    })

            }

        },
        GetGeneralInfo(id) {
            axios
                .get(`/building/generalinformation/${id}/`)
                .then((response) => {
                    this.generalinformation = response.data;
                    this.coords = response.data.geolocation;

                });
        },
        EditMap(id) {
            this.modal = true
            if (this.coor) {
                this.coords = this.coor.split(',')
            } else {

                this.coords = this.districts[`${id}`] && this.districts[`${id}`].coor ? this.districts[`${id}`].coor : [41.31629324503914, 69.26717199864542]
            }

        },
        SaveEditMap() {
            this.modal = false
            this.coor = this.coords.join()
            this.spec1.geolocation = this.coords;
        },
        clicked(e) {
            if (!this.disabled) {
                this.coords = e.get("coords");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.file-error {
    color: red;
}

.error-p {
    display: none;
}

.file-error .error-p {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    display: block !important;
}

.Form {
    .el-form-item__content {
        margin-left: 0 !important;
    }
}

.el-upload-list__item {
    transition: none;
}
</style>
