<template>
    <div v-if="knumber == 6" :element-loading-text="$t('connecting')" v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="top-header">
            <div class="flex items-center gap-3">
                <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                    <i class="el-icon-arrow-left"></i>
                </button>
                <h1>{{ $route.params.edit ? $route.params.edit == '0' ? `"${exist1.name_cyr}" - ${$t('building_data')}` : `"${exist1.name_cyr}" - ${$t('change_building_data')}` : $t('exist_building_add')}}</h1>
            </div>
        </div>
        <div v-if="['review', 'new'].includes($route.query.status) && ['admin', 'org_admin'].includes(role)" class="w-full p-3 rounded-xl bg-gray-100 mb-8 flex items-center gap-2">
            <el-popover placement="bottom" width="310" v-model="visibleApprove">
                <p class="mb-3">{{ $t('unattested_approve_warn') }}</p>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visibleApprove = false">{{ $t('cancel')}}</el-button>
                    <el-button type="primary" size="mini" @click="setStatus('approved', exist1.id)">{{$t('approve_btn') }}</el-button>
                </div> 
                <button slot="reference" class="button bg-green-500 text-white font-bold">{{$t('approve_btn') }}</button>
            </el-popover>
            <el-popover placement="bottom" width="310" v-model="visibleReject">
                <p class="mb-3">{{ $t('unattested_reject_warn') }}</p>
                <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visibleReject = false">{{ $t('cancel')}}</el-button>
                    <el-button type="primary" size="mini" @click="reject_info.id = exist1.id; reject_info.modal = true">{{ $t('reject_btn')}}</el-button>
                </div>
                <button slot="reference" class="button bg-red-500 text-white font-bold">{{$t('reject_btn') }}</button>
            </el-popover>
        </div>
        <div class="wp-body">
            <div v-if="exist1.description" class="w-full p-3 rounded-xl bg-red-50 mb-4">
                <h1 class="font-bold text-sm mb-2">{{ $t('cancel_issue') }}</h1>
                <p class="text-sm">{{ exist1.description }}</p>
            </div>
            <header class="wp-header">
                <div class="flex items-center justify-between w-full">
                    <div class="title-second-header mb-0 ml-0">{{ $t('exist_building_data') }}:</div>
                    <!--                   v-if="exist2 && exist2.finalconclusion==3"-->
                    <div class="flex aligin-center">
                        <p class="m-0 mr-2"> {{ $t('investment_project') }} </p>
                        <InvestmentSwitch v-model="exist1.is_invest" :id="exist1.id" :disabled="true" />
                    </div>
                </div>
            </header>
            <el-collapse v-model="activeNames">
                <el-collapse-item :title="$t('exist_building_data')" name="1">
                    <el-form :model="exist1" :rules="rules1" ref="exist1" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <!--<label class="item" v-if="!$route.params.id && role==='operator' && !$route.query.id">
                                    <p>Бино бириктириладиган объект</p>
                                    <el-form-item prop="organization">
                                        <el-autocomplete :disabled="disabled" class="w-full" valueKey="name_cyr" v-model="state1" :fetch-suggestions="querySearch" @select="handleSelect"></el-autocomplete>
                                    </el-form-item>
                                </label> -->
                                    <label class="item">
                                        <p> {{ $t('cadaster_number') }} </p>
                                        <el-form-item prop="cadastral_number">
                                            <el-input name="cadaster_number" disabled v-model="exist1.cadastral_number"
                                                :placeholder="`${$t('example')}: 10:01:01:01:01:1111:1111:111`"
                                                clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_or_name') }} </p>
                                        <el-form-item prop="name_cyr">
                                            <el-input name="building_or_name" :disabled="disabled"
                                                v-model="exist1.name_cyr" :placeholder="$t('building_or_name')"
                                                clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_or_region') }} </p>
                                        <el-form-item prop="region">
                                            <el-select name="building_or_region" disabled v-model="exist1.region"
                                                :loading="loading" class="w-full" v-if="parseInt(exist1.region) < 16"
                                                :placeholder="$t('building_or_region')">
                                                <el-option v-for="item in dictionary.region" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                            <el-select name="building_or_region" disabled v-model="exist1.region"
                                                :loading="loading" class="w-full" v-else
                                                :placeholder="$t('building_or_region')">
                                                <el-option v-for="item in dictionary.region" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.shared_id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_or_district') }} </p>
                                        <el-form-item prop="district">
                                            <el-input name="building_or_district" disabled v-if="cadastor_data.district"
                                                v-model="cadastor_data.district"
                                                :placeholder="$t('building_or_district')"></el-input>

                                            <el-select name="building_or_district" disabled v-model="exist1.district"
                                                class="w-full" v-else clearable
                                                :placeholder="$t('building_or_district')">
                                                <el-option v-for="item in current_district" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{ $t('building_or_neoghborhood') }} </p>
                                        <el-form-item>
                                            <el-input name="building_or_neoghborhood" disabled
                                                v-model="exist1.neighborhood"
                                                :placeholder="$t('building_or_neoghborhood')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_or_street') }} </p>
                                        <el-form-item prop="address">
                                            <el-input name="building_or_neoghborhood" disabled v-model="exist1.address"
                                                :placeholder="$t('building_or_street')" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('floor_count') }} </p>
                                        <el-form-item prop="numberfloors">
                                            <el-select name="floor_count" :disabled="disabled"
                                                v-model="exist1.numberfloors" :placeholder="$t('floor_count')"
                                                class="w-full" clearable allow-create filterable>
                                                <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{ $t('building_or_year_construction') }} </p>
                                        <el-form-item prop="year_construction">
                                            <!-- !errors.year_construction -->
                                            <el-date-picker name="building_or_year_construction" :disabled="disabled"
                                                style="width: 100%" default-value="2019"
                                                v-model="exist1.year_construction" type="year" value-format="yyyy"
                                                :placeholder="$t('building_or_year_construction')"
                                                :picker-options="pickerOptions">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_one_category') }} </p>
                                        <el-form-item prop="building_group">
                                            <el-select name="building_one_category" v-model="exist1.building_group"
                                                :disabled="disabled" class="w-full" clearable
                                                :placeholder="$t('building_one_category')">
                                                <el-option v-for="item in dictionary.buildinggroup" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_or_geo') }} </p>
                                        <el-form-item prop="geolocation">
                                            <div class="flex align-top">
                                                <el-input name="building_or_geo" disabled style="width: 100%"
                                                    v-model="coor" :placeholder="$t('coor')" />
                                            </div>
                                            <div style="position: relative; overflow: hidden; margin-top: 10px;"
                                                class="flex justify-end">
                                                <el-tooltip style="margin:0" effect="dark"
                                                    :content="disabled ? $t('show_coor') : $t('change_coor')"
                                                    placement="bottom">
                                                    <el-button id="map-button" class="primary-btn"
                                                        style="border-radius:5px; font-size:14px; font-size:14px;   padding:15px 20px;"
                                                        @click="EditMap(exist1.district)">
                                                        <i class="el-icon-location-outline mr-1"></i>
                                                        <span v-if="disabled"> {{ $t('show_coor') }} </span>
                                                        <span v-else> {{ $t('change_coor') }} </span>
                                                    </el-button>
                                                </el-tooltip>
                                            </div>
                                        </el-form-item>

                                    </label>
                                    <!-- <div class="actions mt-10 w-full flex justify-end" v-if="!disabled">
                                        <div>

                                            <el-button @click="save1()" class="primary-btn " style="padding:15px 20px;">
                                                <i class="el-icon-document-checked mr-1"></i>
                                                Кейингиси
                                            </el-button>
                                        </div>
                                    </div> -->
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                    <!-- </el-collapse-item>
                <el-collapse-item title="Бинонинг техник кўрсаткичлари" name="2" :disabled="$route.query.id ?  false : true"> -->
                    <el-form :model="exist2" :rules="rules2" ref="exist2" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{ $t('buildng_constructivesolution') }} </p>
                                        <el-form-item prop="constructivesolution">
                                            <el-select name="buildng_constructivesolution" :disabled="disabled"
                                                v-model="exist2.constructivesolution" class="w-full"
                                                :placeholder="$t('buildng_constructivesolution')" clearable>
                                                <el-option v-for="item in dictionary.constructivesolution"
                                                    :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('building_plannedshape') }} </p>
                                        <el-form-item prop="plannedshape">
                                            <el-select name="building_plannedshape" :disabled="disabled"
                                                :placeholder="$t('building_plannedshape')" v-model="exist2.plannedshape"
                                                class="w-full" clearable>
                                                <el-option v-for="item in dictionary.plannedshape" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p>{{ $t('building_buildingseismicity') }}</p>
                                        <el-form-item prop="buildingseismicity">
                                            <el-select name="building_buildingseismicity" :disabled="disabled"
                                                v-model="exist2.buildingseismicity" class="w-full"
                                                :placeholder="$t('building_buildingseismicity')" clearable>
                                                <el-option v-for="item in dictionary.buildingseismicity" :key="item.id"
                                                    :label="item.number" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>

                                    <div class="item">
                                        <p> {{ $t('building_evral_size') }} </p>
                                        <el-row :gutter="20">
                                            <el-col :span="6">
                                                <el-form-item prop="overall_size.length">
                                                    <el-input name="overall_size_length" type="number"
                                                        :disabled="disabled" v-model="exist2.overall_size.length"
                                                        :placeholder="$t('lengths')" clearable />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-form-item prop="overall_size.width">
                                                    <el-input name="overall_size_width" type="number"
                                                        :disabled="disabled" v-model="exist2.overall_size.width"
                                                        :placeholder="$t('width')" clearable />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-form-item prop="overall_size.height">
                                                    <el-input name="overall_size_height" type="number"
                                                        :disabled="disabled" v-model="exist2.overall_size.height"
                                                        :placeholder="$t('height')" clearable />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-form-item prop="overall_size.unit">
                                                    <el-select name="overall_size_unit" :disabled="disabled"
                                                        v-model="exist2.overall_size.unit" class="w-full" clearable>
                                                        <el-option v-for="item in units.unit_1" :key="item.id"
                                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                            :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <label class="item" style="margin-top: 0;">
                                        <p>{{ $t('floors_height') }}</p>
                                        <div class="flex">
                                            <el-form-item class="flex-auto w-full" prop="floors_height.value">
                                                <el-input name="floors_height" :disabled="disabled"
                                                    :placeholder="$t('floors_height')" type="number"
                                                    v-model="exist2.floors_height.value" clearable />
                                            </el-form-item>
                                            <el-form-item prop="floors_height.unit">
                                                <el-select name="floors_height_unit" :disabled="disabled"
                                                    v-model="exist2.floors_height.unit" class="ml-3" clearable>
                                                    <el-option v-for="item in units.unit_1" :key="item.id"
                                                        :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <div class="item">
                                        <p> {{ $t('building_reconstruction') }} </p>
                                        <template>
                                            <el-radio id="building_reconstruction_on" :disabled="disabled"
                                                v-model="exist2.reconstruction" class="prim" :label="true" border>
                                                {{ $t('done') }} </el-radio>
                                            <el-radio id="building_reconstruction_off" :disabled="disabled"
                                                v-model="exist2.reconstruction" class="prim" :label="false"
                                                border>{{ $t('not_done') }}</el-radio>
                                        </template>
                                    </div>
                                    <label class="item" v-if="exist2.reconstruction">
                                        <p> {{ $t('construction_year') }} </p>
                                        <el-form-item prop="construction_year">
                                            <el-date-picker name="construction_year" :disabled="disabled"
                                                :placeholder="$t('construction_year')" style="width: 100%"
                                                v-model="exist2.construction_year" type="year" value-format="yyyy">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                    <label class="item" v-if="exist2.reconstruction">
                                        <p>{{ $t('reconstruction_reconstruction_durability') }}</p>
                                        <el-form-item prop="reconstructiondurability">
                                            <el-select name="reconstruction_reconstruction_durability"
                                                :disabled="disabled"
                                                :placeholder="$t('reconstruction_reconstruction_durability')"
                                                v-model="exist2.reconstructiondurability" class="w-full" clearable>
                                                <el-option v-for="item in dictionary.reconstructiondurability"
                                                    :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{ $t('dailycondition') }} </p>
                                        <el-form-item prop="dailycondition">
                                            <el-select name="dailycondition" :disabled="disabled"
                                                :placeholder="$t('dailycondition')" v-model="exist2.dailycondition"
                                                class="w-full" clearable>
                                                <el-option v-for="item in dictionary.dailycondition" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>

                                    <div class="item">
                                        <p> {{ $t('divided_into_sections') }} </p>
                                        <template>
                                            <el-radio id="divided_into_sections" :disabled="disabled"
                                                v-for="(item, i) in dictionary.dividedintosections" :key="i"
                                                v-model="exist2.divided_into_sections" class="prim" :label="item.id"
                                                border>
                                                {{ item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] :
                                                item.name_cyr }}
                                            </el-radio>
                                        </template>
                                    </div>
                                    <label class="item">
                                        <p> {{ $t('loadbearingstatus') }} </p>

                                        <el-form-item prop="loadbearingstatus">
                                            <el-select name="loadbearingstatus" :disabled="disabled"
                                                v-model="exist2.loadbearingstatus" class="w-full" clearable
                                                :placeholder="$t('loadbearingstatus')">
                                                <el-option v-for="item in dictionary.loadbearingstatus" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{ $t('earthquakecondition') }} </p>
                                        <el-form-item prop="earthquakecondition">
                                            <el-select name="earthquakecondition" :disabled="disabled"
                                                :placeholder="$t('earthquakecondition')"
                                                v-model="exist2.earthquakecondition" class="w-full" clearable>
                                                <el-option v-for="item in dictionary.earthquakecondition" :key="item.id"
                                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                                    :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <!-- File 1 -->
                                    <div class="item border-t-2 pt-5"
                                        :class="errors.project_cadastre_file ? 'file-error' : ''">
                                        <div class="upload-file">
                                            <div class="upload-file-text">
                                                <button type="button">
                                                    {{ $t('choose_file') }}
                                                    <input name="building_cadaster_doc" type="file" :disabled="disabled"
                                                        ref="project_cadastre_file"
                                                        @change="fileUpload($event, 'project_cadastre_file')"
                                                        accept=".xlsx,.xls,.jpg,.png,.jpeg,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                                </button>
                                                <p class="ml-5 text-base"> {{ $t('building_cadaster_doc') }} </p>
                                            </div>

                                            <div class="upload-file-list" :class="disabled ? 'disabled' : ''">
                                                <div class="upload-file-list-item"
                                                    v-for="(item, i) in fileList.project_cadastre_file" :key="i"
                                                    :class="item.loading ? 'progress' : ''">
                                                    <p> <a :href="item.file" target="_blank" download><i
                                                                class="el-icon-document"
                                                                style="margin-right:5px;"></i>{{ item.name }} </a></p>
                                                    <div class="contols-upload">
                                                        <button type="button" class="upload-delete"
                                                            @click="RemoweFile(item, 'project_cadastre_file', i)"> <i
                                                                class="el-icon-close"></i>
                                                        </button>
                                                        <button type="button" class="upload-success"> <i
                                                                class="el-icon-success"></i> </button>
                                                        <button type="button" class="upload-progress"> <i
                                                                class="el-icon-loading"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="file-Size">
                                                <span>Битта файл ҳажми 30МБ дан ошмаслиги керак! </span>
                                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ----------------------- -->
                                    <!-- File 2 -->
                                    <div class="item border-t-2 pt-5"
                                        :class="errors.generalconditionfile ? 'file-error' : ''">
                                        <div class="upload-file">
                                            <div class="upload-file-text">
                                                <button type="button">
                                                    {{ $t('choose_file') }}
                                                    <input name="building_type_images" type="file"
                                                        ref="generalconditionfile"
                                                        @change="fileUpload($event, 'generalconditionfile')"
                                                        accept=".xlsx,.xls,.jpg,.png,.jpeg,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                                </button>
                                                <p class="ml-5 text-base"> {{ $t('building_type_images') }} </p>
                                            </div>

                                            <div class="upload-file-list">
                                                <div class="upload-file-list-item"
                                                    v-for="(item, i) in fileList.generalconditionfile" :key="i"
                                                    :class="item.loading ? 'progress' : ''">
                                                    <p> <a :href="item.file" target="_blank" download> <i
                                                                class="el-icon-document" style="margin-right:5px;"></i>
                                                            {{ item.name }} </a></p>
                                                    <div class="contols-upload">
                                                        <button type="button" class="upload-delete"
                                                            @click="RemoweFile(item, 'generalconditionfile', i)"> <i
                                                                class="el-icon-close"></i>
                                                        </button>
                                                        <button type="button" class="upload-success"> <i
                                                                class="el-icon-success"></i> </button>
                                                        <button type="button" class="upload-progress"> <i
                                                                class="el-icon-loading"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="file-Size">
                                                <span>Битта файл ҳажми 30МБ дан ошмаслиги керак! </span>
                                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ------------------------------------------ -->
                                    <!-- File 3 -->
                                    <div class="item border-t-2 pt-5 "
                                        :class="errors.existing_damage_file ? 'file-error' : ''">
                                        <div class="upload-file">
                                            <div class="upload-file-text">
                                                <button type="button">
                                                    {{ $t('choose_file') }}
                                                    <input type="file" name="building_broken_file"
                                                        ref="existing_damage_file"
                                                        @change="fileUpload($event, 'existing_damage_file')"
                                                        accept=".xlsx,.xls,.jpg,.png,.jpeg,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                                </button>
                                                <p class="ml-5 text-base"> {{ $t('building_broken_file') }} </p>
                                            </div>

                                            <div class="upload-file-list">
                                                <div class="upload-file-list-item"
                                                    v-for="(item, i) in fileList.existing_damage_file" :key="i"
                                                    :class="item.loading ? 'progress' : ''">
                                                    <p> <a :href="item.file" target="_blank" download> <i
                                                                class="el-icon-document" style="margin-right:5px;"></i>
                                                            {{ item.name }} </a></p>
                                                    <div class="contols-upload">
                                                        <button type="button" class="upload-delete"
                                                            @click="RemoweFile(item, 'existing_damage_file', i)"> <i
                                                                class="el-icon-close"></i>
                                                        </button>
                                                        <button type="button" class="upload-success"> <i
                                                                class="el-icon-success"></i> </button>
                                                        <button type="button" class="upload-progress"> <i
                                                                class="el-icon-loading"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="file-Size">
                                                <span>Битта файл ҳажми 30МБ дан ошмаслиги керак! </span>
                                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ------------------------------------------ -->

                                    <!-- File 4 -->
                                    <div class="item border-t-2 pt-5"
                                        :class="errors.strengthenconsolidatefile ? 'file-error' : ''">
                                        <div class="upload-file">
                                            <div class="upload-file-text">
                                                <button type="button">
                                                    {{ $t('choose_file') }}
                                                    <input type="file" name="building_rec_file"
                                                        ref="strengthenconsolidatefile"
                                                        @change="fileUpload($event, 'strengthenconsolidatefile')"
                                                        accept=".xlsx,.xls,.jpg,.png,.jpeg,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                                </button>
                                                <p class="ml-5 text-base"> {{ $t('building_rec_file') }} </p>
                                            </div>

                                            <div class="upload-file-list">
                                                <div class="upload-file-list-item"
                                                    v-for="(item, i) in fileList.strengthenconsolidatefile" :key="i"
                                                    :class="item.loading ? 'progress' : ''">
                                                    <p> <a :href="item.file" target="_blank" download> <i
                                                                class="el-icon-document" style="margin-right:5px;"></i>
                                                            {{ item.name }} </a></p>
                                                    <div class="contols-upload">
                                                        <button type="button" class="upload-delete"
                                                            @click="RemoweFile(item, 'strengthenconsolidatefile', i)">
                                                            <i class="el-icon-close"></i>
                                                        </button>
                                                        <button type="button" class="upload-success"> <i
                                                                class="el-icon-success"></i> </button>
                                                        <button type="button" class="upload-progress"> <i
                                                                class="el-icon-loading"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="file-Size">
                                                <span>Битта файл ҳажми 30МБ дан ошмаслиги керак! </span>
                                                <p class="error-p">{{ $t('please_choose_file') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ------------------------------------------ -->
                                    <div class="mb-2 mt-10 pt-5 w-full" v-if="!disabled">
                                        <div class=" w-full flex justify-end">
                                            <div>
                                                <el-button @click="save1()" id="save-button" class="primary-btn"
                                                    style="padding:15px 20px;">
                                                    <i class="el-icon-document-checked mr-1"></i> {{ $t('save') }}
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
            <el-row class="flex items-end" :gutter="20">
                <el-col class="mt-5" :span="12">
                    <label class="item" v-if="finalconclusion_cyr">
                        <p class="mTitle"> {{ $t('reconstructionsecondstep_finalconclusion') }} </p>
                        <el-alert class="mt-2" :title="finalconclusion_cyr" :type="final_type" show-icon=""
                            :closable="false">
                        </el-alert>
                    </label>
                </el-col>
                <el-col class="mt-10 " :span="12" v-if="CurYear >= 30">
                    <el-button v-if="'id' in exist2" class="primary-btn w-full"
                        @click.native="$router.push({ name: 'reconstruction', query: { id: $route.query.id }, params: { id: $route.params.id } })">
                        <i class="el-icon-more mr-2"></i>{{ $t('building_last_data') }}
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <div v-if="modal"  class="modal fs-14" :class="modal?'active':''">
            <div class="modal-cover"></div>
            <div class="modal-item padding-map" style="width:95%; height:95%;">
                <div class="flex justify-between modal-absolute-header">
                    <p class="modal-title "> {{ disabled ? `${$t('show_coor')}:` : `${$t('change_coor')}:` }}</p>
                    <button style="font-size:16px; " @click="modal = false"><i class="el-icon-close"
                            style="font-weight:600;"></i>
                    </button>
                </div>
                <div class="w-full map-item h-full">
                    <yandex-map @click="clicked" :coords="coords" style="height:100%; width: 100%" :zoom="14"
                        :controls="['zoomControl']">
                        <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                    </yandex-map>
                </div>
                <div class="flex w-full justify-between modal-bottom fw-w">
                    <div class="flex items-center modal-inputes">
                        <p class="flex items-senter" style="font-size:16px;"><b class="mr-2">{{ $t('coor') }}:</b>
                            {{ coords.join() }} </p>
                    </div>
                    <div class=" modal-inputes flex justify-center">
                        <button @click="modal = false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i>
                            {{ $t('close') }}</button>
                        <button v-if="!disabled" class="ml-2" style="padding:15px 15px; background:green; "
                            @click="SaveEditMap()"> <i class="el-icon-document-checked mr-2"></i>
                            {{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>

        <el-dialog :title="$t('unattested_reject_warn')" :visible.sync="reject_info.modal" width="400px">
            <div class="w-full">
                <p class="text-sm font-bold mb-0.5">{{ $t('cancel_issue') }}</p>
                <el-input type="textarea" :rows="4" placeholder="..."
                    v-model="reject_info.description"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="reject_info.modal = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="setStatus('rejected', reject_info.id)">{{ $t('save') }}</el-button>
            </span>

        </el-dialog>


    </div>
    <div class="fixed-item" v-else v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="fixed-cover"></div>
        <div class="about-knumber">
            <button class="mt-3">
                <iconInfo />
            </button>
            <button class="mt-3">
                <iconQuestion />
            </button>
        </div>
        <div class="pr kadaster-item">
            <div class="erorrsk" v-if="knumber == 2">
                <p>{{ $t('no_cadaster_number') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 'is_changed'">
                <p class="mb-4">Ушбу объектнинг биноларининг кадастр рақами янгиланган, бино қўшишдан олдин эски
                    биноларни
                    кадастр рақамини янгиланг !</p>
                <router-link :to="`/objects/changed-building-list/${changed_object_id}`">
                    <button class="px-3 py-2 bg-purple-600 rounded-xl text-xs">Янгилаш</button>
                </router-link>
            </div>
            <div class="uncompletek" v-if="knumber == 3">
                <p>{{ $t('uncomplate_cadaster_data') }}</p>
            </div>
            <div class="succesk" v-if="knumber == 4">
                <p>{{ $t('cadaster_number_succes_find') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 5">
                <p> {{ $t('building_have_server') }} </p>
            </div>
            <div class="erorrsk" v-if="knumber == 7">
                <p> {{ $t('error_ser') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 8">
                <p>{{ $t('error_cadaster_ser') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 9">
                <p>{{ $t('none_object_for_building') }}</p>
            </div>
            <div class="erorrsk" v-if="knumber == 10">
                <p>{{ $t('only_add_flat') }}</p>
            </div>

            <div class="pb-1">
                <span style="color:#fff; font-size:16px;"> {{ $t('add_building_cadaster') }} </span>
            </div>
            <div class="pb-3 mb-4">
                <div class="flex items-center gap-1">
                    <el-input name="cadastral_number" style="min-width:400px;" class="k-input"
                        :class="knumber == 1 || knumber == 5 || knumber == 8 || knumber == 2 ? 'error' : knumber == 3 ? 'connecting' : knumber == 4 ? 'succes' : ''"
                        v-model="exist1.cadastral_number" placeholder="Мисол: 10:01:01:01:01:1111:0001" clearable />
                    <button class="px-3 py-2 rounded-lg bg-green-600 text-white"
                        @click="CheckKadastor()">Текшириш</button>
                </div>
                <p v-if="knumber == 10" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('only_add_flat') }}</p>
                <p v-if="knumber == 9" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('none_object_building') }} </p>
                <p v-if="knumber == 7" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('error_with_server') }} </p>
                <p v-if="knumber == 5" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_number_have_in') }}</p>
                <p v-if="knumber == 7" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('error_with_server') }} </p>
                <p v-if="knumber == 3" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_find_uncomplate_data') }}</p>
                <p v-if="knumber == 4" style="color:rgb(101, 236, 101); padding-top:5px; font-size:16px;">
                    {{ $t('cadaster_number_find_succes') }}</p>
                <p v-if="knumber == 2" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('noregister_cadaster_number') }}</p>
                <p v-if="knumber == 1" style="color:rgb(251, 76, 76); padding-top:5px; font-size:16px;">
                    {{ $t('uncomplate_cadaster_number') }}</p>
            </div>
            <div class=" flex justify-end">
                <button class="kt-button" style="background:#004787; color:#fff;" @click="$router.go(-1)">
                    {{ $t('cancel') }}</button>
                <button class="kt-button ml-1 bg10" v-if="knumber == 3" style=" color:#fff;" @click="knumber = 6"><i
                        class="el-icon-document mr-1"></i> {{ $t('continue_add') }}</button>
                <button class="kt-button ml-1 bg10" v-if="knumber == 4" style=" color:#fff;" @click="knumber = 6"> <i
                        class="el-icon-document-checked mr-1"></i> {{ $t('continue_add') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Moment from 'moment';
import InvestmentSwitch from "@/components/InvestmentSwitch";
import districts from '@/assets/files/districts.json'

// ICONS
import iconInfo from './Icons/iconInfo.vue';
import iconQuestion from './Icons/iconQuestion.vue';

import {
    extendMoment,

} from 'moment-range';
import axios from 'axios';
const moment = extendMoment(Moment);
export default {
    components: { InvestmentSwitch, iconInfo, iconQuestion },
    props: {
        cadastral_number: '',
    },
    data() {
        return {
            dialogVisible: false,
            unattestedStatus: false,
            visibleApprove: false,
            visibleReject: false,
            a: '',
            knumber: 0,
            ErrorStatus: 0,
            generalinformation: {},
            cadastor_data: {},
            districts: districts,
            modal: false,
            progressPercent: 0,
            errors: {
                generalinformation: false,
                cadastral_number: false,
                region: false,
                district: false,
                address: false,
                year_construction: false,
                numberfloors: false,
                building_group: false,
                neighborhood: false,
                neighborhood_id: false,
                project_cadastre_file: false,
                generalconditionfile: false,
                existing_damage_file: false,
                strengthenconsolidatefile: false
            },
            state1: '',
            pickerOptions: {
                disabledDate(date) {
                    return date > new Date('2020');
                },

            },
            CurYear: 'AA',
            finalconclusion_cyr: '',
            finalconclusion: {},
            disabled: true,
            recRole: false,
            loading: false,
            obj: {},
            fileList: {
                project_cadastre_file: [],
                generalconditionfile: [],
                existing_damage_file: [],
                strengthenconsolidatefile: []
            },
            files: {
                project_cadastre_file: [],
                generalconditionfile: [],
                existing_damage_file: [],
                strengthenconsolidatefile: []
            },

            bool: {
                v1: '1'
            },
            exist1: {
                generalinformation: this.$route.params.id,
                cadastral_number: this.cadastral_number,
                name_uz: "",
                name_ru: "",
                name_cyr: "",
                region: null,
                district: null,
                address: "",
                year_construction: null,
                numberfloors: null,
                building_group: null,
                geolocation: null,
                neighborhood: '',
                neighborhood_id: null,
            },
            rules1: {
                cadastral_number: [{
                    required: true,
                    message: this.$t('please_add_cadastrel_number'),
                    trigger: "change",
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                name_cyr: [{
                    required: true,
                    message: this.$t('please_add_building_name'),
                    trigger: "change",
                },],
                region: [{
                    required: true,
                    message: this.$t('choose_region'),
                    trigger: "change"
                },],
                district: [{
                    required: true,
                    message: this.$t('choose_district'),
                    trigger: "change",
                },],
                address: [{
                    required: true,
                    message: this.$t('choose_adress'),
                    trigger: "change",
                },],
                numberfloors: [{
                    required: true,
                    message: this.$t('choose_numberfloors'),
                    trigger: "change",
                },],
                year_construction: [{
                    required: true,
                    message: this.$t('choose_year_construction'),
                    trigger: "change"
                },
                {
                    required: true,
                    max: 2020,
                    message: this.$t('choose_2020'),
                    trigger: "change"
                },
                ],

                building_group: [{
                    required: true,
                    message: this.$t('choose_building_group'),
                    trigger: "change"
                },],
                geolocation: [{
                    required: true,
                    message: this.$t('choose_geolocation'),
                    trigger: "change",
                },],
            },
            exist2: {
                constructivesolution: null,
                plannedshape: null,
                overall_size: {
                    length: null,
                    width: null,
                    height: null,
                    unit: 1
                },
                floors_height: {
                    value: null,
                    unit: 1
                },
                construction_year: 0,
                reconstruction: false,
                reconstructiondurability: null,
                dailycondition: null,
                divided_into_sections: 2,
                loadbearingstatus: null,
                earthquakecondition: null,
                buildingseismicity: null,
            },
            rules2: {
                constructivesolution: [{
                    required: true,
                    message: this.$t('choose_constructivesolution'),
                    trigger: "change",
                },
                    // { min: 3, max: 5, message: 'Кадастр рақами ', trigger: 'blur' }
                ],
                plannedshape: [{
                    required: true,
                    message: this.$t('choose_plannedshape'),
                    trigger: "change",
                },],
                buildingseismicity: [{
                    required: true,
                    message: this.$t('choose_buildingseismicity'),
                    trigger: "change"
                },],
                overall_size: {
                    length: [{
                        required: true,
                        message: this.$t('choose_overall_size_length'),
                        trigger: "change",
                    },],
                    width: [{
                        required: true,
                        message: this.$t('choose_overall_size_width'),
                        trigger: "change",
                    },],
                    height: [{
                        required: true,
                        message: this.$t('choose_overall_size_height'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                floors_height: {
                    value: [{
                        required: true,
                        message: this.$t('choose_floors_height_value'),
                        trigger: "change",
                    },],
                    unit: [{
                        required: true,
                        message: this.$t('choose_unit'),
                        trigger: "change",
                    },],
                },
                reconstruction: [{
                    required: true,
                    message: this.$t('choose_reconstruction'),
                    trigger: "change"
                },],
                construction_year: [{
                    message: this.$t('choose_construction_year'),
                    trigger: "change",
                },],
                dailycondition: [{
                    required: true,
                    message: this.$t('choose_dailycondition'),
                    trigger: "change"
                },],
                divided_into_sections: [{
                    required: true,
                    message: this.$t('choose_divided_into_sections'),
                    trigger: "change",
                },],
                loadbearingstatus: [{
                    required: true,
                    message: this.$t('choose_loadbearingstatus'),
                    trigger: "change",
                },],
                earthquakecondition: [{
                    required: true,
                    message: this.$t('choose_earthquakecondition'),
                    trigger: "change",
                },],
            },
            current_district: [],
            coor: '',
            coords: [41.31629324503914, 69.26717199864542],
            textarea1: null,
            value3: null,
            value: null,
            value1: null,
            activeNames: ['1'],
            file: [],
            changed_object_id: '',
            reject_info: {
                modal: false,
                id: '',
                action: '',
                description: ''
            }
        };
    },
    computed: {
        final_type() {
            let a = 'info'
            if ('id' in this.finalconclusion) {
                if (this.finalconclusion.id === 1) {
                    a = 'success'
                } else if (this.finalconclusion.id === 2) {
                    a = 'info'
                } else if (this.finalconclusion.id === 3) {
                    a = 'warning'
                } else if (this.finalconclusion.id === 4) {
                    a = 'error'
                }
            }
            return a;
        },
        dictionary() {
            return this.$store.state.dictionary;
        },

        units() {
            return this.$store.state.units;
        },
        isCreated() {
            return !!this.$route.query.id;
        },
        role() {
            return this.$store.state.role
        },
        activer() {
            return this.$store.state.activeName
        },
        disabletItem() {
            return this.$store.state.disabletItem
        },
        cadasterLemgth() {
            let data = 0
            if (this.exist1.cadastral_number) {
                data = this.exist1.cadastral_number.length
            }
            return data
        }
    },
    watch: {
        coords: function () {
            this.exist1.geolocation = this.coords;
        },
        geo() {
            this.coords = this.geo.join()
        },
        cadastral_number() {
            this.exist1.cadastral_number = this.cadastral_number
        },
        'fileList.project_cadastre_file'(val) {
            if (val.length > 0) {
                this.errors.project_cadastre_file = false
            }
        },
        'fileList.generalconditionfile'(val) {
            if (val.length > 0) {
                this.errors.generalconditionfile = false
            }
        },
        'fileList.existing_damage_file'(val) {
            if (val.length > 0) {
                this.errors.existing_damage_file = false
            }
        }
    },
    created() {
        // this.$root.$on('unattested-status', this.handleUnattestedStatus)
    },
    mounted() {
        // this.$root.$on('unattested-status', this.handleUnattestedStatus)
        this.activeNames = this.activer
        if (this.$route.params.edit == '0') {
            this.disabled = true
        } else {
            this.disabled = false
        }
        if (this.disabled) {
            this.activeNames = ['1']
        }
        this.$store.commit('set_activename', ['1'])
        this.__GET();
        if (this.$route.query.id) {
            this.knumber = 6
        }

    },
    methods: {
        handleUnattestedStatus(data) {
            this.unattestedStatus = data
            console.log(this.unattestedStatus, 'aaa')
        },
        setStatus(action, id) {
            let data = {
                id: id,
                status: action === 'approved' ? 'approved' : 'rejected',

            };

            if (action === 'approved') {
                this.$notify({
                    title: this.$t('approve_n'),
                    type: 'success'
                });
            } else if (action === 'rejected') {
                this.$notify.info({
                    title: this.$t('unattested_rejected_n'),
                });
                data.description = this.reject_info.description
            }

            axios.patch(`/building/status/`, data);
            this.visibleApprove = false;
            this.visibleReject = false;
            this.$router.go(-2);
        },
        // Keremas
        querySearch(queryString, cb) {
            axios.get(`/building/generalinformation/?limit=100&search=${queryString}`)
                .then((response) => {
                    cb(response.data.results)
                })
        },
        createFilter() {
            return (link) => {
                return link.name_cyr
            };
        },
        handleSelect(item) {
            this.exist1.cadastral_number = item.cadastral_number
            this.coords = item.geolocation.join()
            this.exist1.generalinformation = item.id
            this.a = item
        },

        //   Keremas
        __GET() {
            if (this.$route.query.id) {
                this.loading = true
                axios.get(`/building/existingspecification/${this.$route.query.id}/`)
                    .then((response) => {
                        this.exist1 = response.data;
                        this.exist1.buildingtype = 'EXG'
                        this.coor = response.data.geolocation.join();
                        this.CurrentYear(response.data.created_at);

                        // this.$router.push({
                        //         name: 'exist-build',
                        //         params: {
                        //             id: this.$route.params.id
                        //         },
                        //         query: {
                        //             status: this.$route.query.status,
                        //         }
                        //     })
                        this.exist1.year_construction = String(this.exist1.year_construction)
                        // this.getDistrict();
                    })
                this.getFiles('project_cadastre_file');
                this.getFiles('generalconditionfile');
                this.getFiles('strengthenconsolidatefile');
                this.getFiles('existing_damage_file');
                axios.get(`/building/existingspecification/${this.$route.query.id}/secondstep/`)
                    .then((response) => {
                        if (response.data.overall_size) {
                            this.exist2 = response.data;
                            this.exist2.construction_year = String(response.data.construction_year);
                            if (response.data.finalconclusion) {
                                axios.get(`/dictionary/finalconclusion/${response.data.finalconclusion}/`)
                                    .then((response) => {
                                        if (response.data) {
                                            this.finalconclusion_cyr = response.data.name_cyr;
                                            this.finalconclusion = response.data;
                                        }
                                    })
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })

            }
        },
        fileUrl(val) {
            return axios.defaults.baseURL + `dictionary/${val}/`;
        },
        CurrentYear(a) {
            a = a.split(".")
            a = a[2] + "-" + a[1] + "-" + a[0]
            var b = new Date()
            b = b.getFullYear() + "-" + String(b.getMonth() + 1).padStart(2, '0') + "-" + String(b.getDate()).padStart(2, '0');;
            const start = moment(a);
            const end = moment(b);
            const range = moment.range(start, end);
            this.CurYear = parseInt(range.diff('days'));
        },
        RemoweFile(file, link, index) {
            if (this.$route.query.id && this.$route.query.id != undefined) {
                return this.$confirm(`${this.$t('do_delete_file')} ${file.name} ?`)
                    .then(() => {
                        axios.delete(`/dictionary/${link}/${file.id}/`)
                            .then((response) => {
                                this.getFiles(`${link}`);
                            })
                            .catch(error => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_delete_file'),
                                    timer: 3000
                                });

                            })
                    })
            } else {

                this.fileList[`${link}`].splice(index, 1);
                this.files[`${link}`].splice(index, 1);

            }
        },
        uploadFile(files, i, id, link) {
            if (i < files[link].length && i != files[link].length) {
                let formData = new FormData();
                formData.append('name', files[link][i].name)
                formData.append('file', files[link][i].file)

                formData.append(link, id)
                axios.post(this.fileUrl(link), formData)
                    .then((response) => {
                        // this.getFiles(link);
                    })
                i = i + 1;
                this.uploadFile(files, i, id, link)
            } else {
                return 0
            }

        },
        fileUpload(event, link) {
            var file = event.target.files[0]
            this.$refs[`${link}`].value = '';
            if (file.size < 1024 * 1024 * 30) {
                if (this.$route.query.id && this.$route.query.id != undefined) {
                    this.fileList[`${link}`].push({
                        name: file.name,
                        file: file,
                        loading: true
                    })
                    let formData = new FormData();
                    formData.append('name', file.name)
                    formData.append('file', file)
                    formData.append(`${link}`, this.$route.query.id)
                    axios.post(this.fileUrl(`${link}`), formData)
                        .then((response) => {
                            this.getFiles(`${link}`);
                            this.fileList[`${link}`][(this.fileList[`${link}`].length - 1)].loading = false
                        })

                } else {
                    this.fileList[`${link}`].push({
                        name: file.name,
                        file: file
                    })
                    this.files[`${link}`].push({
                        name: file.name,
                        file: file
                    })
                }
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Битта файл ҳажми 30МБ дан ошмаслиги керак!',
                    timer: 3000
                });
            }

        },
        getFiles(name) {
            axios.get(`/dictionary/${name}/?${name}=${this.$route.query.id}`)
                .then((response) => {
                    this.fileList[name] = response.data;
                })
        },
        getDistrict() {
            // this.loading = true;
            // axios.get(`/dictionary/district/?region=${this.exist1.region}`)
            //     .then((response) => {
            //         this.current_district = response.data;
            //     })
            //     .finally(() => {
            //         this.loading = false;
            //     })
        },
        save2(id) {
            this.$refs.exist2.validate((valid) => {
                if (valid) {
                    console.log(id)
                    this.loading = true;
                    delete this.exist2.finalconclusion;
                    if (this.exist2.construction_year === "null") {
                        this.exist2.construction_year = null;
                    }
                    if (!this.$route.query.id || this.$route.query.id == undefined) {
                        this.uploadFile(this.files, 0, id, 'generalconditionfile')
                        this.uploadFile(this.files, 0, id, 'existing_damage_file')
                        this.uploadFile(this.files, 0, id, 'strengthenconsolidatefile')
                        this.uploadFile(this.files, 0, id, 'project_cadastre_file')
                    }
                    axios.patch(`/building/existingspecification/${id}/secondstep/`, this.exist2)
                        .then((response) => {
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('data_saved'),
                                timer: 3000
                            })
                            if (this.$route.query.status == 'rejected') {
                                this.$router.push({
                                    path: `/unattested-building-list/exg`
                                })
                            } else if (this.$route.params.obj) {
                                this.$router.push({
                                    path: `/objects/building-list/${this.$route.params.obj}`
                                })
                            } else {
                                this.$router.push({
                                    path: `/building-list/exg`
                                })
                            }
                            this.__GET();

                        })
                        .catch(error => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_save'),
                                timer: 3000
                            })
                        })
                        .finally(() => {
                            this.loading = false;
                        })

                }

            })
        },
        save1() {
            this.$refs.exist1.validate((valid1) => {
                this.$refs.exist2.validate((valid2) => {
                    if (valid1 && valid2) {

                        if (this.fileList.project_cadastre_file.length > 0 && this.fileList.generalconditionfile.length > 0 && (this.exist2.loadbearingstatus >= 2 && this.fileList.existing_damage_file.length > 0 || this.exist2.loadbearingstatus != 2)) {
                            this.loading = true
                            if (typeof this.exist1.geolocation == 'string') {
                                this.exist1.geolocation = this.exist1.geolocation.split(',');
                            }
                            var data = {
                                name_cyr: this.exist1.name_cyr,
                                year_construction: this.exist1.year_construction,
                                geolocation: this.exist1.geolocation,
                                building_group: this.exist1.building_group,
                                numberfloors: this.exist1.numberfloors,
                            }
                            axios({

                                method: !this.$route.query.id || this.$route.query.id == undefined ? 'POST' : 'PATCH',
                                url: !this.$route.query.id ? '/building/existingspecification/' : `/building/existingspecification/${this.$route.query.id}/`,
                                data: !this.$route.query.id ? this.exist1 : data,
                            })
                                .then((response) => {
                                    this.save2(response.data.id)
                                    this.$store.commit('set_activename', ['1'])
                                    // this.$router.push({
                                    //     name: 'exist-build',
                                    //     params: {
                                    //         id: this.$route.params.id,
                                    //         obj: this.$route.params.obj
                                    //     },
                                    //     query: {
                                    //         id: response.data.id
                                    //     }
                                    // })
                                    // this.activeNames = ["2"]

                                })
                                .catch(error => {
                                    console.log(error)
                                    this.$swal.fire({
                                        icon: 'error', title: this.$t('error_save'), timer: 3000
                                    })
                                })
                        } else {
                            if (this.fileList.project_cadastre_file.length == 0) {
                                this.errors.project_cadastre_file = true
                            }
                            if (this.fileList.generalconditionfile.length <= 0) {
                                this.errors.generalconditionfile = true
                            }
                            if (this.exist2.loadbearingstatus >= 2 && this.fileList.existing_damage_file.length <= 0) {
                                this.errors.existing_damage_file = true
                            }
                        }
                    } else {
                        if (this.fileList.project_cadastre_file.length == 0) {
                            this.errors.project_cadastre_file = true
                        }
                        if (this.fileList.generalconditionfile.length <= 0) {
                            this.errors.generalconditionfile = true
                        }
                        if (this.exist2.loadbearingstatus >= 2 && this.fileList.existing_damage_file.length <= 0) {
                            this.errors.existing_damage_file = true
                        }
                    }

                })
            })
        },
        handlePreview(file) {
            const url = file.file
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'a')
            document.body.appendChild(link)
            link.click()
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`Cancel the transfert of ${file.name} ?`);
        },
        CheckKadastor() {
            if (this.exist1.cadastral_number.length == 0) {
                this.knumber = 0
            }
            else if (this.exist1.cadastral_number.length < 15) {
                this.knumber = 1
            }
            else {
                this.loading = true
                var data = {
                    type: "building",
                    cad_num: this.exist1.cadastral_number
                }
                this.erors = []
                axios.get(`/building/cadastral/${this.exist1.cadastral_number}/`)
                    .then(res => {
                        if (res.data.result || res.data.object == null) {
                            this.loading = false
                            if (!res.data.object && res.data.result == 0) {
                                this.knumber = 9
                            } else {
                                this.knumber = 5
                            }

                        }
                        else if (res.data && res.data.is_changed == false) {
                            this.loading = false
                            this.knumber = 'is_changed'
                            this.changed_object_id = res.data.object
                        }
                        else {
                            axios.get(`/building/generalinformation/${res.data.object}/`)
                                .then((response) => {
                                    this.generalinformation = response.data

                                });
                            this.exist1.generalinformation = res.data.object
                            axios.post('/dictionary/insert/cadastr/', data)
                                .then((res) => {
                                    this.a = res.data
                                    if (res.data.result == 0) {
                                        this.knumber = 2
                                        this.loading = false
                                        this.errors.message = res.data.message
                                    } else if (res.data.result === 2) {
                                        // this.knumber = 10
                                        // this.loading = false
                                        // this.errors.message = res.data.message
                                        this.knumber = 2
                                        this.loading = false
                                        this.errors.message = res.data.message
                                    } else {
                                        if (res.data.objects) {
                                            var b = res.data.objects.filter((item) => {
                                                return item.cadastral_number_object === this.exist1.cadastral_number
                                            })
                                            if (b.length > 0) {
                                                // Street
                                                this.cadastor_data = res.data
                                                if (res.data.address) {
                                                    var address = res.data.address.split(",")
                                                    if (address.length > 3) {
                                                        this.exist1.address = address[0] + address[1]
                                                    } else {

                                                        address = res.data.address.split(".")
                                                        if (address.length > 2) {
                                                            this.exist1.address = address[0] + address[1]
                                                        } else {
                                                            this.exist1.address = res.data.address
                                                        }
                                                    }
                                                    this.errors.address = false

                                                } else {
                                                    this.errors.address = true
                                                }
                                                // Region
                                                if (res.data.region_id) {
                                                    this.exist1.region = res.data.region_id
                                                    this.errors.region = false
                                                } else {
                                                    this.errors.region = true
                                                }

                                                // District
                                                axios.get(`/dictionary/district/?region__shared_id=${this.exist1.region}`)
                                                    .then((response) => {
                                                        this.current_district = response.data;
                                                        this.exist1.district = res.data.district_id
                                                    })
                                                // Neighboard
                                                if (res.data.neighborhood) {
                                                    this.exist1.neighborhood = res.data.neighborhood
                                                    this.errors.neighborhood = false
                                                } else {
                                                    this.errors.neighborhood = true
                                                }
                                                if (res.data.neighborhood_id) {
                                                    this.exist1.neighborhood_id = res.data.neighborhood_id
                                                    this.errors.neighborhood_id = false
                                                } else {
                                                    this.errors.neighborhood_id = true
                                                }
                                                // date building
                                                if (res.data.year) {
                                                    var date = res.data.year.split(".")
                                                    if (date.length > 1) {
                                                        this.exist1.year_construction = date[2]
                                                    } else {
                                                        date = res.data.year.split("-")
                                                        this.exist1.year_construction = date[0]
                                                    }
                                                    this.errors.year_construction = false
                                                } else {
                                                    this.errors.year_construction = true

                                                }
                                                if (b[0].numberfloors) {
                                                    this.exist1.numberfloors = parseInt(b[0].numberfloors)
                                                    this.errors.numberfloors = false
                                                } else {
                                                    this.errors.numberfloors = true

                                                }
                                                this.knumber = 4
                                                if (this.errors.year_construction) {
                                                    this.knumber = 3
                                                }
                                                this.loading = false
                                            } else {
                                                this.knumber = 2
                                                this.loading = false
                                                this.errors.message = res.data.message
                                            }
                                        } else {
                                            this.knumber = 2
                                            this.loading = false
                                            this.errors.message = res.data.message
                                        }

                                    }

                                })
                                .catch(e => {
                                    this.loading = false
                                    this.knumber = 8
                                })
                        }

                    })
                    .catch(e => {
                        this.knumber = 7
                        this.loading = false
                    })
            }


        },
        EditMap(id) {
            this.modal = true
            if (this.coor) {
                this.coords = this.coor.split(',')
            } else {

                this.coords = this.districts[`${id}`].coor
            }

        },
        SaveEditMap() {
            this.modal = false
            this.coor = this.coords.join()
            this.exist1.geolocation = this.coords;
        },
        clicked(e) {
            if (!this.disabled) {
                this.coords = e.get("coords");
            }
        },
        handleProgress(ev, file, fileLIst) {
            file.raw['status'] = "uploading";
        },
        handleSuccess(res, file, fileLIst) {
            file.raw['status'] = "success";
        },
    },
};
</script>

<style lang="scss" scoped>
.file-error {
    color: red;
}

.top-header {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-action {
        &-btn {
            background: #004787;
            color: #fff;
            font-weight: 600;
            padding: 12px 20px;
            border-radius: 12px;
        }
    }
}

.error-p {
    display: none;
}

.file-error .error-p {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    display: block !important;
}
  
</style>
